// Dependencies
import i18n from "i18next"
import { Content } from "core/content"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
    // Language initialization
    resources: {
        ...Content,
    },
    fallbackLng: "fa",
    debug: false,

    // Have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
