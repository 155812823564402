export const tools = {
    thousandsSeparator: (input: number) => input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    getUserToken: () => {
        const userToken = localStorage.getItem("UserToken")
        if (userToken) return userToken
        return null
    },
    setUserToken: (userToken: string) => {
        if (!userToken) return
        localStorage.setItem("UserToken", userToken)
    },
    getUserLang: () => {
        const userLang = localStorage.getItem("UserLang")
        if (userLang) return userLang
        return null
    },
    setUserLang: (userLang: string) => {
        if (!userLang) return
        localStorage.setItem("UserLang", userLang)
    },
    getFifaUserToken: () => {
        const userToken = localStorage.getItem("FifaUserToken")
        if (userToken) return userToken
        return null
    },
    setFifaUserToken: (userToken: string) => {
        if (!userToken) return
        localStorage.setItem("FifaUserToken", userToken)
    },
    exitUser: () => localStorage.clear(),
    touchMoveDirectionDetector: (xDown: number, xUp: number, yDown: number, yUp: number): SwipeDirType => {
        var xDiff = xDown - xUp
        var yDiff = yDown - yUp

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                return "left"
            } else {
                return "right"
            }
        } else {
            if (yDiff > 0) {
                /* down swipe */
                return "down"
            } else {
                /* up swipe */
                return "up"
            }
        }
    },
    getCookie: (name: any) => {
        const cookieArr = document.cookie.split(";")
        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].split("=")
            if (name == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1])
            }
        }
        return null
    },
    setCookie: (name: any, value: any, daysToLive: any) => {
        let cookie = name + "=" + encodeURIComponent(value)
        if (typeof daysToLive === "number") {
            cookie += "; max-age=" + daysToLive * 24 * 60 * 60
            document.cookie = cookie
        }
    },
    sleep: async (ms: number) => await new Promise(resolve => setTimeout(resolve, ms)),
}

export type SwipeDirType = "left" | "right" | "up" | "down" | null
