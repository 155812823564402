import { Avatar, Skeleton } from "antd"

// Utilities
import { ClockActive, Gold, Image, Silver } from "core/utilities/ImageImport"

// Interfaces & Types
import { TournamentType } from "core/api/types/TournamentTypes"
import { useEffect, useState } from "react"
import { tools } from "core/functions/tools"
import { Trans, useTranslation } from "react-i18next"

interface Props {
    tournament: TournamentType
    isActive?: boolean
}

interface IOfferCard extends React.FC<Props> {
    Skeleton: React.FC<{}>
}

// Methods
const calculateTime = (time: any) => {
    const startTimeUnix = new Date().getTime()
    const endTimeUnix = new Date(time).getTime()
    const actualTime = new Date(endTimeUnix - startTimeUnix)

    return {
        hours: actualTime.getHours(),
        minutes: actualTime.getMinutes(),
        seconds: actualTime.getSeconds(),
    }
}

const TournamentCard: IOfferCard = ({ tournament, isActive = true }) => {
    // Render
    const [time, setTime] = useState<any>(calculateTime(isActive ? tournament?.endTime : tournament.startTime))
    const { t } = useTranslation()

    useEffect(() => {
        calculateTime(isActive ? tournament?.endTime : tournament.startTime)

        const timer = setInterval(() => {
            setTime(calculateTime(isActive ? tournament?.endTime : tournament.startTime))
        }, 1000)

        return () => clearInterval(timer)
    }, [])

    return (
        <div
            className={`relative flex flex-col justify-end p-2 overflow-hidden w-80 h-48 rounded-xl ${
                !isActive && "grayscale"
            }`}
        >
            {/* This is background image */}
            <img
                className="absolute top-0 left-0 object-cover w-full h-full"
                src={tournament.picUrl}
                alt={`${tournament.name} Background`}
            />
            <div className="flex items-center w-full px-3 py-2 mt-4 bg-black bg-opacity-40 backdrop-blur-sm rounded-xl">
                <div className="flex flex-col w-full gap-y-1">
                    {/* Header */}
                    <div className="flex items-center justify-between">
                        <div className="text-base font-semibold">{tournament.name}</div>
                        <div className="flex items-center gap-x-2">
                            <span className="text-xs">
                                <Trans>Entrance</Trans>
                            </span>
                            <img className="w-5" alt="mode" src={tournament.entranceType === 1 ? Silver : Gold} />
                            <span>{tournament.entrance}</span>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="flex h-full">
                            <span className="text-2xs">
                                <Trans>Prize Pool</Trans>
                            </span>
                        </div>
                        <div className="flex items-center justify-between gap-x-2">
                            <div className="text-sm">
                                {tools.thousandsSeparator(
                                    tournament.prizePools[tournament.prizePools.length - 1].reward.amount
                                )}
                            </div>
                            <div>
                                <img
                                    className="w-5"
                                    alt="mode"
                                    src={tournament.prizePools[0].reward.type === 1 ? Silver : Gold}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="flex flex-col mt-1 gap-y-4">
                        <div className="flex items-center justify-between">
                            <div className="flex items-end gap-x-2">
                                {!isActive && (
                                    <span>
                                        <Trans>Starts In</Trans>:
                                    </span>
                                )}
                                {isActive && (
                                    <span>
                                        <Trans>Ends In</Trans>:
                                    </span>
                                )}
                                <img className="w-4" alt="mode" src={ClockActive} />
                                <span className="text-2xs">{`
                            ${time?.hours}
                        ${t("Hours")}
                             ${time?.minutes} ${t("Minutes")} ${time?.seconds} ${t("Seconds")}
                            `}</span>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <Avatar.Group>
                                    {tournament.activePlayers.avatars?.map((src: any, index: number) => (
                                        <Avatar
                                            size="small"
                                            className="w-4 h-4"
                                            key={`title-avatar-${index}`}
                                            src={src}
                                        />
                                    ))}
                                </Avatar.Group>
                                <div className="text-xs">{tournament.activePlayers.playCount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

TournamentCard.Skeleton = () => (
    <div className="w-72 h-36">
        <Skeleton.Image active />
    </div>
)

export { TournamentCard }
