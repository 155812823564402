import { Button, Input } from "antd"
import { useApi } from "core/api/ApiProvider"
import { Phone } from "core/utilities/ImageImport"
import { useState } from "react"
import { toast } from "react-toastify"
import { Trans, useTranslation } from "react-i18next"

interface ILoginWithPhoneComponent {
    setStep: Function
    phoneNumber: string
    setPhoneNumber: Function
}

const LoginWithPhone: React.FC<ILoginWithPhoneComponent> = ({ setStep, phoneNumber, setPhoneNumber }) => {
    // States and Hooks
    const { user } = useApi()
    const { t } = useTranslation()
    const [loginButtonLoading, setLoginButtonLoading] = useState<boolean>(false)

    // Methods
    const onLoginButtonClick = async () => {
        if (phoneNumber.length !== 11) return toast.error(t("Invalid phone number"))

        setLoginButtonLoading(true)
        const { error } = await user.loginWithPhoneNumber({ phoneNumber })
        setLoginButtonLoading(false)

        if (error) return toast.error("خطا در ارسال کد")
        setStep("CONFIRM_CODE")
    }

    // Render
    return (
        <div className="flex flex-col items-center">
            <h3 className="text-3xl text-white mb-2">
                <Trans>Login</Trans>
            </h3>
            <div className="w-1/3 h-1 rounded-full bg-gradient-to-tr from-blue-500 to-sky-400"></div>
            <div className="flex justify-between w-full mt-8">
                <h2 className="text-xl text-white">
                    <Trans>Login with Phone Number</Trans>
                </h2>
                <img src={Phone} className="w-6 h-6" alt="phone-icon" />
            </div>
            <Input
                type="tel"
                pattern="[0-9]*"
                placeholder={t("Enter your phone number").toString()}
                value={phoneNumber}
                className="text-white mt-5 px-4 py-3 border-white border border-solid bg-transparent rounded-xl w-full focus:outline-none text-center"
                onChange={e => setPhoneNumber(e.target.value)}
            />
            <Button
                className={`w-full bg-gradient-to-tr ${
                    loginButtonLoading ? "from-gray-600  to-gray-400" : "from-blue-600  to-sky-400"
                } border-0 rounded-xl h-12 mt-4 text-2xl`}
                type="primary"
                disabled={loginButtonLoading}
                onClick={() => onLoginButtonClick()}
            >
                <Trans>Confirm</Trans>
            </Button>
        </div>
    )
}

export { LoginWithPhone }
