import { Button } from "antd"
import React from "react"
import { Modal } from "./Modal"
import { Logo, IosShare, IosAddIcon, IosAddText } from "core/utilities/ImageImport"

type Props = {
    onClose: Function
}

const SafariInstallModal: React.FC<Props> = ({ onClose }) => {
    return (
        <Modal onClose={onClose}>
            <div className="flex flex-col w-80 gap-8 bg-neutral-700 rounded-3xl px-5 py-5 mx-auto">
                <div className="flex flex-col items-center border-b border-neutral-500 gap-4 pb-4">
                    <div>
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="text-center leading-6 text-sm">
                        وب‌اپلیکیشن فان‌کرفت را به صفحه اصلی موبایل خود اضافه کنید.
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-4">
                        <div className="w-1/12">
                            <img src={IosShare} alt="ios-share" />
                        </div>
                        <div className="text-white w-11/12">1- در نوار پایین روی دکمه Share بزنید.</div>
                    </div>
                    <div className="flex items-center gap-4">
                        <div className="w-1/12">
                            <img src={IosAddIcon} alt="ios-share" />
                        </div>
                        <div className="text-white w-11/12">
                            2- در منوی باز شده، در قسمت پایین، گزینه را انتخاب کنید.{" "}
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <div className="w-1/12">
                            <img src={IosAddText} alt="ios-share" />
                        </div>
                        <div className="text-white w-11/12">3- در مرحله بعد در قسمت بالا روی Add بزنید.</div>
                    </div>
                </div>
                <Button
                    onClick={() => onClose()}
                    size="large"
                    className="rounded-xl w-3/5 mx-auto border-none bg-gradient-to-tr from-sky-600 to-sky-400"
                >
                    <span className="text-white">متوجه شدم</span>
                </Button>
            </div>
        </Modal>
    )
}

export { SafariInstallModal }
