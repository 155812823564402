import { Col, Row, Skeleton } from "antd"
interface Player {
    avatar: string
    score?: number
}

interface Props {
    gameImage: string
    title: string
    desc: string
    players: {
        user: Player
        competitor: Player
    }
}

interface IOnGoingTournamentCard extends React.FC<Props> {
    Skeleton: React.FC<{}>
}

const OnGoingTournamentCard: IOnGoingTournamentCard = ({ gameImage, title, desc, players }) => {
    return (
        <div className="flex flex-col px-3 py-4 bg-primary-light rounded-3xl w-full">
            <Row gutter={[10, 5]}>
                <Col span={4}>
                    <img className="object-cover w-full aspect-square rounded-xl" src={gameImage} alt="" />
                </Col>
                <Col>
                    <h3 className="text-white text-lg">{title}</h3>
                    <div className="text-gray-300 text-xs text-right">{desc}</div>
                </Col>
            </Row>
        </div>
    )
}

OnGoingTournamentCard.Skeleton = () => (
    <div className="flex flex-col bg-slate-600 rounded-3xl px-3 py-4">
        <div className="flex items-center gap-2 ">
            <Skeleton.Button style={{ width: "3rem", height: "3rem" }} shape="circle" active />
            <div className="flex flex-col w-full">
                <div className="w-1/2">
                    <Skeleton.Button size="small" active block />
                </div>
                <div className="w-1/4">
                    <Skeleton.Button className=" mt-1" size="small" active block />
                </div>
            </div>
        </div>
    </div>
)

export { OnGoingTournamentCard }
