import { Avatar } from "antd"
import { GameProgressIndicator } from "components/game/GameProgressIndicator"
import { StartGameCard } from "components/game/StartGameCard"
import { Layout } from "components/template/Layout"
import { tools } from "core/functions/tools"
import { AcademicHat, ArrowLeft, ChevronRight } from "core/utilities/Icons"
import { Link, useNavigate } from "react-router-dom"
import {
    StartGameGold,
    StartGameSilver,
    StartGameBronze,
    Silver,
    StartGamePractice,
    Gold,
} from "core/utilities/ImageImport"
import { AppRoutes } from "core/utilities/AppRoutes"
import { TabsObject, useGame } from "core/providers/GameProvider"
import { useApp } from "core/providers/AppProvider"
import { Qualification } from "components/game/Qualification"
import { InActiveStartGame } from "components/game/InActiveStartGame"
import { UserGameModeType } from "core/api/types/GameTypes"
import { NotEnoughCoinsModal } from "components/game/NotEnoughCoinsModal"
import { GameErrors } from "core/utilities/ErrorIndicators"
import { LeaderBoard } from "components/game/LeaderBoard"
import { LeaderBoardSwitch } from "components/game/LeaderBoardSwitch"
import { ScreenLoader } from "components/template"
import { Trans } from "react-i18next"

export const Game = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { setCurrentGame, currentUser } = useApp()
    const {
        loadingGame,
        game,
        userGameModes,
        highScore,
        currentError,
        clearError,
        tab,
        startGame,
        leaderBoard,
        setCurrentStartedGameRank,
        rankData,
    } = useGame()
    const showQualification: boolean = userGameModes.filter(mode => mode.isActive === true)[0]?.rank === 1
    const practiceGameMode: UserGameModeType | undefined = userGameModes.find(item => item.rank === 0)

    const activeGameModes = userGameModes.filter(
        gameMode => gameMode.rank !== 0 && gameMode.rank !== 1 && gameMode.isActive
    )

    const deActiveGameModes = userGameModes.filter(
        gameMode => gameMode.rank !== 0 && gameMode.rank !== 1 && !gameMode.isActive
    )

    // Render
    if (loadingGame) return <ScreenLoader />

    return (
        <>
            {currentError === GameErrors.NOT_ENOUGH_COINS && <NotEnoughCoinsModal onClose={() => clearError()} />}
            {/* TODO: use ErrorModal for this after merging */}
            {currentError === GameErrors.PROBLEM_STARTING && <div>Error loading the game</div>}
            <Layout showControlCapsule={true} showHeader={false}>
                {/* Header bg picture */}
                <img
                    className="absolute top-0 left-0 rounded-b-2xl w-full h-80 object-cover"
                    src={
                        game?.bannerPath
                            ? game?.bannerPath
                            : "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202105/photo-1585620385456-4759f9b5c7_1200x768.jpeg?FBnzbNtZQtXS8JsNwQGVjNs5Th8WXGnW&size=770:433"
                    }
                    alt="Game banner"
                />

                <nav className="flex justify-between items-center w-full ltr z-10 py-3">
                    <button
                        className="bg-white bg-opacity-30 backdrop-blur-lg p-2 rounded-full"
                        onClick={() => navigate(AppRoutes.dashboard.home)}
                    >
                        <ArrowLeft className="w-5 h-5" />
                    </button>
                    <div className="py-1 px-1.5 rounded-full bg-white bg-opacity-30 backdrop-blur-lg">
                        <div className="flex gap-2.5">
                            <div className="flex gap-1">
                                <img src={Gold} alt="gold" />
                                <span className="font-semibold">
                                    {tools.thousandsSeparator(currentUser?.goldCoin ?? 0)}
                                </span>
                            </div>
                            <div className="flex gap-1">
                                <img src={Silver} alt="gold" />
                                <span className="font-semibold">
                                    {tools.thousandsSeparator(currentUser?.silverCoin ?? 0)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <Link
                        to={`${AppRoutes.dashboard.game.onboarding}/${game?.clientId}`}
                        className="bg-white bg-opacity-30 backdrop-blur-lg p-2 rounded-full"
                        onClick={() => setCurrentGame(game)}
                    >
                        <AcademicHat className="w-5 h-5" />
                    </Link>
                </nav>

                <div className="z-10">
                    <div className="flex items-center justify-between mt-48 rtl">
                        <h3 className="text-white text-2xl font-bold">
                            <div className="px-5 backdrop-blur-lg bg-gray-900 bg-opacity-50 rounded-4xl inline">
                                {game?.name}
                            </div>
                        </h3>
                        <div className="">
                            <div className="flex items-center gap-2 px-3 py-1 backdrop-blur-lg bg-gray-900 bg-opacity-50 rounded-2xl">
                                <Avatar.Group>
                                    {game?.avatars.map((src, index) => (
                                        <Avatar size="small" key={`title-avatar-${index}`} src={src} />
                                    ))}
                                </Avatar.Group>
                                <div>{tools.thousandsSeparator(game?.playCount ?? 0)}</div>
                            </div>
                        </div>
                    </div>

                    {showQualification ? (
                        <Qualification />
                    ) : (
                        <Link
                            to={`${AppRoutes.dashboard.game.progress}/${game?.clientId}`}
                            className="flex items-center w-full bg-gradient-to-b from-amber-900 to-amber-800 py-4 px-6 rounded-3xl mt-2 ltr"
                        >
                            <div className="w-full">
                                <GameProgressIndicator
                                    levelImage={
                                        rankData?.ranks.find(rank => rank.type === rankData.currentRank)?.icon ?? ""
                                    }
                                    min={
                                        rankData?.ranks.find(rank => rank.type === rankData.currentRank)?.minTrophy ?? 0
                                    }
                                    max={
                                        rankData?.ranks.find(rank => rank.type === rankData.currentRank)?.maxTrophy ?? 0
                                    }
                                    current={rankData?.currentTrophy ?? 0}
                                />
                            </div>
                            <ChevronRight className="w-6 h-6" />
                        </Link>
                    )}
                </div>

                <LeaderBoardSwitch />

                {tab === TabsObject.game && (
                    <>
                        <h3 className="text-white text-2xl font-bold">
                            <Trans>Duel</Trans>
                        </h3>
                        <p className="text-gray-400 text-sm mt-2">
                            <Trans>Duel With</Trans>
                        </p>

                        <div className="flex flex-col gap-2 mt-4">
                            <>
                                {activeGameModes.map(({ rank, rewards, entrance, entranceType }, index) => {
                                    return (
                                        <StartGameCard
                                            key={index}
                                            rank={rank}
                                            action={() => {
                                                setCurrentGame(game)
                                                startGame({ rank, entranceFee: entrance })
                                                setCurrentStartedGameRank(rank)
                                            }}
                                            btnContent={
                                                <div className="flex justify-center items-center text-lg gap-2 w-full">
                                                    <div className="flex items-center gap-1 z-10">
                                                        <span className="font-medium">
                                                            {tools.thousandsSeparator(entrance)}
                                                        </span>
                                                        <img
                                                            src={entranceType === 1 ? Silver : Gold}
                                                            className="w-7 h-7"
                                                            alt="coin"
                                                        />
                                                    </div>
                                                    <span>
                                                        <Trans>Start Match</Trans>
                                                    </span>
                                                </div>
                                            }
                                            description=""
                                            rewardData={{
                                                type: rewards[0].type === 1 ? "silver" : "gold",
                                                amount: Number(rewards[0].amount),
                                            }}
                                            bgImageUrl={
                                                rank === 2
                                                    ? StartGameBronze
                                                    : rank === 3
                                                    ? StartGameSilver
                                                    : StartGameGold
                                            }
                                        />
                                    )
                                })}
                                {deActiveGameModes[0] && (
                                    <InActiveStartGame
                                        entrance={deActiveGameModes[0].entrance}
                                        rewardData={{
                                            type: deActiveGameModes[0].rewards[0].type === 1 ? "silver" : "gold",
                                            amount: Number(deActiveGameModes[0].rewards[0].amount),
                                        }}
                                        openingRank={deActiveGameModes[0].rank}
                                    />
                                )}
                            </>
                        </div>

                        {showQualification ? (
                            <div>
                                <h3 className="text-white text-2xl font-bold mt-8">
                                    <Trans>Practice</Trans>
                                </h3>
                                <p className="text-gray-400 text-sm mt-2">
                                    <Trans>Practice Description</Trans>
                                </p>
                                <div className="mt-4">
                                    <InActiveStartGame
                                        openingRank={practiceGameMode?.rank ?? 0}
                                        entrance={practiceGameMode?.entrance ?? 0}
                                        rewardData={{
                                            type: practiceGameMode?.rewards[0].type === 1 ? "silver" : "gold",
                                            amount: Number(practiceGameMode?.rewards[0].amount),
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <h3 className="text-white text-2xl font-bold mt-8">
                                    <Trans>Practice</Trans>
                                </h3>
                                <p className="text-gray-400 text-sm mt-2">
                                    <Trans>Practice Description</Trans>
                                </p>

                                <div className="flex flex-col gap-2 mt-4">
                                    <StartGameCard
                                        action={() => {
                                            setCurrentGame(game)
                                            startGame({ rank: 1, entranceFee: 0 })
                                            setCurrentStartedGameRank(1)
                                        }}
                                        rank={1}
                                        btnContent={
                                            <div className="flex justify-center items-center text-lg gap-2">
                                                <div className="flex items-center gap-1 z-10">
                                                    {practiceGameMode?.entrance ?? 0}
                                                    <img
                                                        src={practiceGameMode?.entranceType === 1 ? Silver : Gold}
                                                        className="w-7 h-7"
                                                        alt="coin"
                                                    />
                                                </div>
                                                <span>
                                                    <Trans>Start Match</Trans>
                                                </span>
                                            </div>
                                        }
                                        bgImageUrl={StartGamePractice}
                                        rewardData={{
                                            amount: Number(practiceGameMode?.rewards[0].amount) ?? 0,
                                            type: "silver",
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        <h3 className="text-white text-2xl font-bold mt-8">
                            <Trans>Your Stats</Trans>
                        </h3>
                        <div className="relative flex py-4 px-6 mt-2">
                            <img
                                className="absolute top-0 left-0 w-full h-full  rounded-full object-cover"
                                src={StartGamePractice}
                                alt="Earn coin background"
                            />
                            <div className="flex justify-between w-full z-10">
                                <h3 className="text-lg text-white font-bold">
                                    <Trans>High Score</Trans>
                                </h3>
                                <h3 className="text-xl text-yellow-300">{tools.thousandsSeparator(highScore)}</h3>
                            </div>
                        </div>
                    </>
                )}
                {tab === TabsObject.leaderBoard && (
                    <>
                        <h3 className="text-white text-2xl font-bold">
                            <Trans>Seasonal Leaderboard</Trans>
                        </h3>
                        <p className="text-gray-400 text-sm mt-2">
                            <Trans>Leaderboard Description</Trans>
                        </p>
                        {leaderBoard ? (
                            <div className="px-4 mt-4">
                                <LeaderBoard data={leaderBoard} />
                            </div>
                        ) : (
                            <div className="px-4 mt-4">
                                <p className="text-white text-lg">اطلاعاتی برای نمایش وجود ندارد</p>
                            </div>
                        )}
                    </>
                )}

                <div className="py-16"></div>
            </Layout>
        </>
    )
}

export default Game
