import { Avatar, Col, Row, Skeleton } from "antd"
import { tools } from "core/functions/tools"
interface Player {
    avatar: string
    score?: number
}

interface Props {
    gameImage: string
    title: string
    desc: string
    players: {
        user: Player
        competitor: Player
    }
}

interface IOnGoingGameCard extends React.FC<Props> {
    Skeleton: React.FC<{}>
}

const OnGoingGameCard: IOnGoingGameCard = ({ gameImage, title, desc, players }) => {
    return (
        <div className="flex flex-col px-3 py-4 bg-primary-light rounded-3xl w-full">
            <Row gutter={[10, 5]}>
                <Col span={4}>
                    <img className="object-cover w-full aspect-square rounded-xl" src={gameImage} alt="" />
                </Col>
                <Col>
                    <h3 className="text-white text-lg">{title}</h3>
                    <div className="text-gray-300 text-xs flex">{desc}</div>
                </Col>
            </Row>

            <Row justify="space-between" align="middle" className="mt-4">
                <Col span={9} className="flex justify-around items-center gap-2">
                    <Avatar className="w-12 h-12" src={players.competitor.avatar}></Avatar>
                    <span className="text-yellow-400 text-base">
                        {players.competitor?.score && tools.thousandsSeparator(players.competitor.score)}
                    </span>
                    {!players.competitor?.score && <span className="text-lg"> - </span>}
                </Col>
                <Col span={3} className="text-center text-xl">
                    :
                </Col>
                <Col span={9} className="flex flex-row-reverse justify-around items-center gap-2">
                    <Avatar className="w-12 h-12 " src={players.user.avatar}></Avatar>
                    <span className="text-yellow-400 text-base">
                        {players.user?.score && tools.thousandsSeparator(players.user.score)}
                    </span>
                </Col>
            </Row>
        </div>
    )
}

OnGoingGameCard.Skeleton = () => (
    <div className="flex flex-col bg-slate-600 rounded-3xl px-3 py-4">
        <div className="flex items-center gap-2 ">
            <Skeleton.Button style={{ width: "3rem", height: "3rem" }} shape="circle" active />
            <div className="flex flex-col w-full">
                <div className="w-1/2">
                    <Skeleton.Button size="small" active block />
                </div>
                <div className="w-1/4">
                    <Skeleton.Button className=" mt-1" size="small" active block />
                </div>
            </div>
        </div>
        <div className="flex justify-between mt-4">
            <Skeleton.Button shape="circle" size="large" active />
            <Skeleton.Button shape="circle" size="large" active />
        </div>
    </div>
)

export { OnGoingGameCard }
