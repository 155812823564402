import { BronzeMedal, GoldMedal, SilverMedal } from "core/utilities/ImageImport.js"
import { tools } from "core/functions/tools"
import { Skeleton } from "antd"
import { MedalTypeLocalization } from "core/providers/GameProvider"
import { Trans } from "react-i18next"

export const UserLevels = {
    0: "دست گرمی",
    1: "کیفیت سنجی",
    2: "برنز",
    3: "نقره",
    4: "طلا",
}

const medals = {
    0: BronzeMedal,
    1: BronzeMedal,
    2: BronzeMedal,
    3: SilverMedal,
    4: GoldMedal,
}
interface Props extends React.HTMLProps<HTMLDivElement> {
    title: string
    titleSize?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "8xl" | "9xl"
    description: string
    imageUrl: string
    imageClassName?: string
    level: 0 | 1 | 2 | 3 | 4
    score: number
    rankPicUrl: string
    rankName: string
}

interface GameScoreCardInterface extends React.FC<Props> {
    Skeleton: React.FC
}

const GameScoreCard: GameScoreCardInterface = ({
    title,
    titleSize,
    description,
    imageUrl,
    imageClassName,
    className,
    level = 0,
    score,
    rankPicUrl,
    rankName,
    ...rest
}) => {
    return (
        <div className={`flex justify-between ${className}`} {...rest}>
            <div className="flex gap-4 items-center">
                <img
                    src={imageUrl}
                    className={`object-cover rounded-xl h-full ${imageClassName}`}
                    alt={`${title}-game`}
                />
                <div className="flex flex-col gap-2">
                    <p className={`mt-1 text-white text-${titleSize}`}>{title}</p>
                    <p className="text-gray-400 text-xs mt-auto mb-1 flex gap-2 items-center">
                        <div>{description}</div>
                        <span className="text-yellow-200 text-center">{tools.thousandsSeparator(score)}</span>
                    </p>
                </div>
            </div>
            <div className="flex flex-col my-auto">
                <img src={rankPicUrl} alt={`medal`} className="w-14 h-14" />
                <p className="text-gray-400 text-xs text-center -mt-2">
                    <Trans>{rankName}</Trans>
                </p>
            </div>
        </div>
    )
}

GameScoreCard.Skeleton = () => (
    <div className="flex gap-3">
        <Skeleton.Image active className="rounded-xl" />
        <div className="flex flex-col gap-2 mt-1">
            <Skeleton.Input active className="rounded-lg" />
            <Skeleton.Input active size="small" className="rounded-lg" />
        </div>
    </div>
)

export { GameScoreCard }
