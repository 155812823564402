import { Button } from "antd"
import { useApi } from "core/api/ApiProvider"
import { tools } from "core/functions/tools"
import { useApp } from "core/providers/AppProvider"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

interface IConfirmCodeComponent {
    setStep: Function
    phoneNumber: string
}

const ConfirmCode: React.FC<IConfirmCodeComponent> = ({ setStep, phoneNumber }) => {
    // States and Hooks
    const timer: any = useRef()
    const { user } = useApi()
    const { setCurrentUser } = useApp()
    const [loginButtonLoading, setLoginButtonLoading] = useState<boolean>(false)
    const [timeLeft, setTimeLeft] = useState<number>(120)
    const [confirmCode, setConfirmCode] = useState<string>("")
    const [isEditButtonVisible, setIsEditButtonVisible] = useState<boolean>(false)
    const minutesLeft = Math.floor(timeLeft / 60)
    const secondsLeft = timeLeft % 60

    // Methods
    const onConfirmButtonClick = async () => {
        setLoginButtonLoading(true)
        const { data, error } = await user.confirmCode({
            phoneNumber: phoneNumber,
            token: confirmCode,
        })
        setCurrentUser(data)
        setLoginButtonLoading(false)

        if (error) return toast.error("کد وارد شده معتبر نمی‌باشد")
        localStorage.setItem("User_ID", data.ID)
        tools.setUserToken(data.deviceToken)
        window.location.href = window.location.href
    }

    useEffect(() => {
        if (timeLeft === 0) return setIsEditButtonVisible(true)

        timer.current = setInterval(() => {
            if (timeLeft > 0) setTimeLeft(prev => prev - 1)
        }, 1000)

        return () => clearInterval(timer.current)
    }, [timeLeft])

    // Render
    return (
        <div className="flex flex-col items-center p-8">
            <h3 className="text-3xl text-white mb-4">کد ارسال شده را وارد کنید</h3>
            <div className="w-1/3 h-1 rounded-full bg-gradient-to-tr from-blue-500 to-sky-400"></div>
            <input
                type="tel"
                pattern="[0-9]*"
                placeholder="کد تایید را وارد کنید "
                value={confirmCode}
                className="text-white mt-8 px-4 py-3 border-white border border-solid bg-transparent rounded-xl w-full focus:outline-none text-center"
                onChange={e => setConfirmCode(e.target.value)}
            />
            <Button
                className="w-full bg-gradient-to-tr from-blue-600  to-sky-400  border-0 rounded-xl h-12 mt-3 text-2xl"
                type="primary"
                disabled={loginButtonLoading}
                onClick={() => onConfirmButtonClick()}
            >
                تایید
            </Button>
            <div className="flex w-full justify-between mt-2">
                <span className="text-base">زمان باقی‌مانده تا ارسال دوباره</span>
                <span className="text-base">
                    0{minutesLeft}:{secondsLeft < 10 ? "0" + secondsLeft : secondsLeft}
                </span>
            </div>
            {isEditButtonVisible && (
                <>
                    <div className="h-1.5 w-full bg-secondary-main rounded-full mt-14"></div>
                    <Button
                        type="text"
                        onClick={() => setStep("INPUT_NUMBER")}
                        className="text-center border-white my-3 h-12 border-0"
                    >
                        <span className="text-2xl text-secondary-main">اصلاح شماره</span>
                    </Button>
                </>
            )}
        </div>
    )
}

export { ConfirmCode }
