export const AppRoutes = {
    android: "/android",
    login: "/login",
    confirmCode: "/confirm-code",
    enterName: "/enter-name",
    register: "/register",
    blackout: "/blackout",
    privacyPolicy: "/privacy-policy",
    chooseLanguage: "/choose-language",
    dashboard: {
        home: "/dashboard",
        profile: "/dashboard/profile",
        userProfile: "/dashboard/user-profile",
        settings: "/dashboard/settings",
        inviteFriends: "/dashboard/invite-friends",
        win: "/dashboard/win",
        lose: "/dashboard/lose",
        awaiting: "/dashboard/awaiting",
        game: {
            home: "/dashboard/game",
            progress: "/dashboard/game/progress",
            play: "/dashboard/game/play",
            onboarding: "/dashboard/game/onboarding",
            playcanvas: "/dashboard/game/playcanvas",
            leaderBoard: "/dashboard/game/leader-board",
            versus: "/dashboard/versus",
        },
        activity: "/dashboard/activity",
        shop: {
            home: "/dashboard/shop",
            verify: "/zarinpal",
        },
        worldCup: {
            home: "/dashboard/world-cup",
            placeBet: "/dashboard/world-cup/place-bet",
            resultBet: "/dashboard/world-cup/result-bet",
        },
        tournament: {
            game: {
                home: "/dashboard/tournament/game",
            },
            versus: "/dashboard/tournament/versus",
            statistics: "/dashboard/tournament/statistics",
            playcanvas: "/dashboard/tournament/playcanvas",
            leaderBoard: "/dashboard/tournament/leader-board",
            result: "/dashboard/tournament/result",
        },
    },
} as const

export const ProtectedRoutes = [
    AppRoutes.dashboard.home,
    AppRoutes.dashboard.profile,
    AppRoutes.dashboard.userProfile,
    AppRoutes.dashboard.settings,
    AppRoutes.dashboard.inviteFriends,
    AppRoutes.dashboard.win,
    AppRoutes.dashboard.lose,
    AppRoutes.dashboard.awaiting,
    AppRoutes.dashboard.game.home,
    AppRoutes.dashboard.game.progress,
    AppRoutes.dashboard.game.play,
    AppRoutes.dashboard.game.onboarding,
    AppRoutes.dashboard.activity,
    AppRoutes.dashboard.shop.home,
    AppRoutes.dashboard.shop.verify,
]
