import { Button } from "antd"
import { Layout } from "components/template/Layout"
import { useAuth } from "core/providers/AuthProvider"
import { LoginWithPhone } from "components/auth/LoginWithPhone"

const Login = () => {
    // States and Hooks
    const { guestLoginButtonLoading, onGuestLoginButtonClick } = useAuth()

    // Render
    return (
        <Layout showHeader={false} showControlCapsule={false} fit={true}>
            <div className="flex flex-col min-h-screen p-8">
                <LoginWithPhone />
                <div className="mt-8">
                    <Button
                        type="link"
                        shape="round"
                        className="text-center w-full h-8 border-gray-500 border-solid"
                        onClick={() => onGuestLoginButtonClick()}
                        loading={guestLoginButtonLoading}
                    >
                        <span className="text-gray-300">ورود به عنوان مهمان</span>
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export default Login
