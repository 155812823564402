import { Layout } from "components/template/Layout"
import { Link } from "react-router-dom"
import { ArrowLeft, ChevronLeft, ChevronRight, Setting } from "core/utilities/Icons"
import { GameScoreCard } from "components/profile/GameScoreCard"
import { Divider } from "antd"
import { useState } from "react"
import { ProfileAvatar } from "components/template/Avatar"
import { AppRoutes } from "core/utilities/AppRoutes"
import { useNavigate } from "react-router-dom"
import { Avatar, Button, Input, Row } from "antd"
import { Drawer } from "components/template/Drawer"
import { Cross } from "core/utilities/Icons"
import { ReferralCode } from "core/utilities/ImageImport"
import { useApp } from "core/providers/AppProvider"
import { useProfile } from "core/providers/ProfileProvider"
import { LoggedInAsGuestBanner } from "components/profile/PhoneNumberLogin"
import { Trans, useTranslation } from "react-i18next"
import i18n from "../i18n"
import { Content } from "core/content"

const Profile = () => {
    // States and Hooks
    const navigate = useNavigate()
    const { currentUser, isGuest } = useApp()
    const { profile, loadingProfile, setReferralCode: postReferralCode, postGiftCode } = useProfile()
    const [isReferralCodeDrawerOpen, setIsReferralCodeDrawerOpen] = useState(false)
    const [referralCode, setReferralCode] = useState("")
    const [isGiftCodeDrawerOpen, setIsGiftCodeDrawerOpen] = useState(false)
    const [giftCode, setGiftCode] = useState("")
    const { t } = useTranslation()
    // Render
    return (
        <>
            <Layout showControlCapsule={false} showHeader={false}>
                <header className="fixed w-full lg:w-[430px] top-0 py-3 -mx-4 px-4 bg-transparent z-50">
                    <nav className="flex justify-between w-full rtl">
                        <Link to={AppRoutes.dashboard.settings} className="bg-primary-light p-2 rounded-full">
                            <Setting className="w-5 h-5" />
                        </Link>
                        <button className="bg-primary-light p-2 rounded-full" onClick={() => navigate(-1)}>
                            <ArrowLeft className="w-5 h-5" />
                        </button>
                    </nav>
                </header>
                <div className="py-6"></div>
                <main className="mt-8">
                    {loadingProfile ? (
                        <ProfileAvatar.Skeleton />
                    ) : (
                        <ProfileAvatar
                            username={currentUser?.name ?? ""}
                            imageUrl={currentUser?.avatarUrl ?? ""}
                            scoreData={{
                                current: currentUser?.xpProgress.value ?? 0,
                                nextLevel: currentUser?.xpProgress.maxValue ?? 200,
                            }}
                            className="w-40 h-40"
                            notificationCount={currentUser?.level ?? 0}
                            disabled={false}
                        />
                    )}
                    {isGuest && <LoggedInAsGuestBanner />}
                    {profile?.games !== null && (
                        <div className="bg-primary-light mt-8 rounded-2xl p-4 ">
                            <h3 className="text-xl text-center text-white">
                                <Trans>Your Scores</Trans>
                            </h3>
                            {loadingProfile
                                ? [1, 2, 3].map(_ => (
                                      <div key={_} className="my-2">
                                          <GameScoreCard.Skeleton />
                                      </div>
                                  ))
                                : profile?.games?.map((game, index, arr) => (
                                      <div key={`game-score-${index}`}>
                                          <GameScoreCard
                                              title={game.name}
                                              titleSize="lg"
                                              description={
                                                  i18n.language === "fa"
                                                      ? Content.fa.translations["High Score"]
                                                      : Content.en.translations["High Score"]
                                              }
                                              score={game.highScore}
                                              level={game.rank}
                                              imageUrl={game.icon || ""}
                                              className="mt-4 justify-between"
                                              imageClassName="w-20"
                                              rankPicUrl={game.rankPicUrl}
                                              rankName={game.rankName}
                                          />
                                          {index !== arr.length - 1 && <Divider className="bg-slate-600" />}
                                      </div>
                                  ))}
                        </div>
                    )}
                    <div className="bg-primary-light mt-8 rounded-2xl p-4 ">
                        {/* CONTENT: Using invite friends instead of introduce to friends for both languages */}
                        {/* <h3 className="text-xl text-center text-white">معرفی به دوستان</h3> */}
                        <h3 className="text-xl text-center text-white">
                            <Trans>Invite Friends</Trans>
                        </h3>
                        <div className="mt-6">
                            <Link to={AppRoutes.dashboard.inviteFriends}>
                                <div className="flex justify-between py-1">
                                    <span className="text-base ">
                                        <Trans>Invite Friends Body</Trans>
                                    </span>
                                    {i18n.language === "fa" ? (
                                        <ChevronLeft className="w-4 h-4 my-auto" />
                                    ) : (
                                        <ChevronRight className="w-4 h-4 my-auto" />
                                    )}
                                </div>
                            </Link>
                            <Divider className="bg-slate-600 my-2" />
                            <button className="w-full" onClick={() => setIsGiftCodeDrawerOpen(true)}>
                                <div className="flex justify-between py-1">
                                    <span className="text-base ">
                                        <Trans>Enter Gift Code</Trans>
                                    </span>
                                    {i18n.language === "fa" ? (
                                        <ChevronLeft className="w-4 h-4 my-auto" />
                                    ) : (
                                        <ChevronRight className="w-4 h-4 my-auto" />
                                    )}
                                </div>
                            </button>
                            <Divider className="bg-slate-600 my-2" />
                            <button className="w-full" onClick={() => setIsReferralCodeDrawerOpen(true)}>
                                <div className="flex justify-between py-1">
                                    <span className="text-base ">
                                        <Trans>Enter Invitation Code</Trans>
                                    </span>
                                    {i18n.language === "fa" ? (
                                        <ChevronLeft className="w-4 h-4 my-auto" />
                                    ) : (
                                        <ChevronRight className="w-4 h-4 my-auto" />
                                    )}
                                </div>
                            </button>
                        </div>
                    </div>
                    {/* <div className="bg-primary-light mt-8 rounded-2xl p-4 ">
                        <h3 className="text-xl text-center text-white">شبکه های اجتماعی</h3>
                        <div className="mt-6">
                            <Link to="#">
                                <div className="flex justify-between py-1">
                                    <span className="text-base ">گروه دیسکورد</span>
                                    <ChevronLeft className="w-4 h-4 my-auto" />
                                </div>
                            </Link>
                            <Divider className="bg-slate-600 my-2" />
                            <Link to="#">
                                <div className="flex justify-between py-1">
                                    <span className="text-base ">کانال تلگرام</span>
                                    <ChevronLeft className="w-4 h-4 my-auto" />
                                </div>
                            </Link>
                            <Divider className="bg-slate-600 my-2" />
                            <Link to="#">
                                <div className="flex justify-between py-1">
                                    <span className="text-base ">اکانت اینستاگرام</span>
                                    <ChevronLeft className="w-4 h-4 my-auto" />
                                </div>
                            </Link>
                        </div>
                    </div> */}
                </main>
            </Layout>
            {/* Referral code drawer */}
            <Drawer
                className="bg-primary-light shadow-2xl rounded-t-3xl p-4 h-2/5"
                isOpen={isReferralCodeDrawerOpen}
                setIsOpen={setIsReferralCodeDrawerOpen}
            >
                <Row justify="center" align="middle" className="relative">
                    <button
                        className="bg-white bg-opacity-20 p-1.5 w-8 h-8 rounded-full absolute right-0"
                        onClick={() => setIsReferralCodeDrawerOpen(false)}
                    >
                        <Cross />
                    </button>
                    <h4 className="text-white text-lg">
                        <Trans>Enter Invitation Code</Trans>
                    </h4>
                </Row>
                <Row justify="center">
                    <div className="w-36 my-4">
                        <Avatar src={ReferralCode} size={130} />
                    </div>
                </Row>
                <Input
                    value={referralCode}
                    onChange={e => setReferralCode(e.target.value)}
                    className="bg-transparent mt-auto mb-4 rounded-lg h-12 text-white"
                    placeholder={t("Enter Code").toString()}
                />
                <Button
                    className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600  border-0 rounded-lg h-12 mt-2 text-lg"
                    type="primary"
                    onClick={() => postReferralCode(referralCode)}
                >
                    تایید
                </Button>
            </Drawer>

            {/* Gift code drawer */}

            <Drawer
                className="bg-primary-light shadow-2xl rounded-t-3xl p-4 h-2/5"
                isOpen={isGiftCodeDrawerOpen}
                setIsOpen={setIsGiftCodeDrawerOpen}
            >
                <Row justify="center" align="middle" className="relative">
                    <button
                        className="bg-white bg-opacity-20 p-1.5 w-8 h-8 rounded-full absolute right-0"
                        onClick={() => setIsGiftCodeDrawerOpen(false)}
                    >
                        <Cross />
                    </button>
                    <h4 className="text-white text-lg">
                        <Trans>Enter Gift Code</Trans>
                    </h4>
                </Row>
                <Row justify="center">
                    <div className="w-36 my-4">
                        <Avatar src={ReferralCode} size={130} />
                    </div>
                </Row>
                <Input
                    value={giftCode}
                    onChange={e => setGiftCode(e.target.value)}
                    className="bg-transparent mt-auto mb-4 rounded-lg h-12 text-white"
                    placeholder={t("Enter Code").toString()}
                />
                <Button
                    onClick={() => postGiftCode(giftCode)}
                    className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600  border-0 rounded-lg h-12 mt-2 text-lg"
                    type="primary"
                >
                    تایید
                </Button>
            </Drawer>
        </>
    )
}

export default Profile
