import { Button, Image } from "antd"
import { Modal } from "components/template/Modal"
import { tools } from "core/functions/tools"
import { useApp } from "core/providers/AppProvider"
import { useShop } from "core/providers/ShopProvider"
import { Cross } from "core/utilities/Icons"
import { Arrow, GoldBig, SilverBig } from "core/utilities/ImageImport"

type Props = {
    onClose: Function
}

export const ConvertGoldToSilverModal: React.FC<Props> = ({ onClose }) => {
    // States and Hooks
    const { currentSelectedPack, purchaseSilverByGold, convertButtonLoading } = useShop()
    const { currentUser } = useApp()

    const hasEnoughCoins = currentSelectedPack && currentUser && currentUser?.goldCoin >= currentSelectedPack?.price

    // Render
    return (
        <Modal onClose={onClose}>
            <div className="flex flex-col gap-2 bg-gradient-to-tr from-slate-900 to-slate-700 w-5/6 p-4 rounded-2xl mx-auto">
                <button
                    onClick={() => onClose()}
                    className="flex items-center justify-center bg-white bg-opacity-20 rounded-full p-1 w-7 h-7"
                >
                    <Cross className="w-full h-full" />
                </button>
                <div className="px-8 text-center">
                    <h3 className="text-white text-2xl ">تبدیل سکه طلایی به نقره‌ای</h3>

                    <div className="mt-4 flex items-start justify-between py-4 gap-2">
                        <div>
                            <div>
                                <Image src={GoldBig} className="h-16 w-16" preview={false} />
                            </div>
                            <div className="font-medium text-base flex flex-col gap-1 items-center mt-2">
                                <div>{tools.thousandsSeparator(currentSelectedPack?.price ?? 0)}</div>
                                <div>سکه طلایی</div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <Image src={Arrow} className="w-10 h-6" />
                        </div>
                        <div>
                            <div>
                                <Image src={SilverBig} className="h-16 w-16" preview={false} />
                            </div>
                            <div className="font-medium text-base flex flex-col gap-1 items-center mt-2">
                                <div>{tools.thousandsSeparator(currentSelectedPack?.amount ?? 0)}</div>
                                <div>سکه نقره‌ای</div>
                            </div>
                        </div>
                    </div>
                </div>
                {hasEnoughCoins ? (
                    <Button
                        type="primary"
                        loading={convertButtonLoading}
                        className="bg-gradient-to-r from-green-600 to-green-300 h-12 rounded-xl border-0 mt-8 hover:from-green-500 hover:to-green-200 hover:bg-gradient-to-r"
                        onClick={async () => {
                            await purchaseSilverByGold()
                            onClose()
                        }}
                    >
                        <span className="text-lg">تبدیل</span>
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        className="bg-gradient-to-r from-red-600 to-red-500 h-12 rounded-xl border-0 mt-8 hover:from-red-500 hover:to-red-400 hover:bg-gradient-to-r"
                        onClick={() => {
                            onClose()
                        }}
                    >
                        <span className="text-lg">سکه کافی ندارید</span>
                    </Button>
                )}
            </div>
        </Modal>
    )
}
