import { createContext, useContext, useState, useEffect } from "react"

// Providers
import { useApi } from "core/api/ApiProvider"

// Functions
import { tools } from "core/functions/tools"

// Interfaces & Types
import { GameType, UserType } from "core/api/types/GameTypes"
import { TournamentType } from "core/api/types/TournamentTypes"
import { AppRoutes } from "core/utilities/AppRoutes"
import i18n from "../../i18n"

type Props = {
    children: JSX.Element
}

interface AppContextInterface {
    currentUser: UserType | null
    setCurrentUser: Function
    loadingApp: boolean
    currentGame: GameType | null
    setCurrentGame: Function
    currentTournament: TournamentType | null
    setCurrentTournament: Function
    fetchUser: Function
    isGuest: boolean
    lang: string
    setLang: Function
}

const initialContextValue = {
    currentUser: null,
    setCurrentUser: () => undefined,
    loadingApp: true,
    currentGame: null,
    setCurrentGame: () => undefined,
    currentTournament: null,
    setCurrentTournament: () => undefined,
    fetchUser: () => undefined,
    isGuest: true,
    lang: "fa",
    setLang: () => undefined,
}

const AppContext = createContext<AppContextInterface>(initialContextValue)

export const useApp = () => useContext(AppContext)

const AppProvider: React.FC<Props> = ({ children }) => {
    // States and Hooks
    const { user } = useApi()
    const [lang, setLang] = useState<string>("en")
    const [currentUser, setCurrentUser] = useState<UserType | null>(null)
    const [currentGame, setCurrentGame] = useState<GameType | null>(null)
    const [currentTournament, setCurrentTournament] = useState<TournamentType | null>(null)
    const loadingApp: boolean = currentUser === null

    // Methods
    const fetchUser = async () => {
        const token = tools.getUserToken()
        if (!token) return
        const { data, error } = await user.loginAsGuest({ token })
        if (!error) {
            localStorage.setItem("User_ID", data.ID)
            return setCurrentUser(data)
        }
    }

    useEffect(() => {
        if (currentUser === null) fetchUser()
    }, [currentUser])

    useEffect(() => {
        const language = tools.getUserLang()
        if (language) {
            i18n.changeLanguage(language)
            setLang(language)
        }
        if (!language)
            if (window.location.pathname !== AppRoutes.chooseLanguage)
                window.location.pathname = AppRoutes.chooseLanguage
    }, [])

    // Binding
    const value: AppContextInterface = {
        // States
        currentUser,
        setCurrentUser,

        currentGame,
        setCurrentGame,

        currentTournament,
        setCurrentTournament,

        loadingApp,

        fetchUser,
        isGuest: currentUser?.phoneNumber === "",

        lang,
        setLang,
    }

    // Render
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppProvider
