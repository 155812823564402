import { ScreenLoader } from "components/template"
import { useApi } from "core/api/ApiProvider"
import { useApp } from "core/providers/AppProvider"
import { useTournament } from "core/providers/TournamentProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"

type Response = {
    score: number
    client_id: string
    client_secret: string
}

const TournamentPlayCanvas = () => {
    // States and Hooks
    const { tournaments } = useApi()
    const navigate = useNavigate()
    const { currentUser, fetchUser } = useApp()
    const { loadingTournament, onGoingTournamentData, tournament, fetchLeaderBoardData } = useTournament()
    const [isLoaded, setIsLoaded] = useState(false)

    // Methods
    const handleEndMatch = async (event: any) => {
        if (event.data.event === "end-game-msg") {
            const result: Response = event.data.data

            await tournaments.endMatch({
                ID: currentUser?.ID,
                clientId: onGoingTournamentData.match.gameClientId,
                matchId: onGoingTournamentData.match.matchId,
                score: result.score,
                secret: result.client_secret,
            })
            await fetchUser()
            await fetchLeaderBoardData({ size: 20, page: 1, continuous: false })

            navigate(`${AppRoutes.dashboard.tournament.result}/${onGoingTournamentData.match.tournamentId}`, {
                replace: true,
            })
        }
    }

    useEffect(() => {
        window.addEventListener("message", handleEndMatch)
        return () => window.removeEventListener("message", handleEndMatch)
    }, [])

    if (loadingTournament) return <ScreenLoader />

    // Render
    return (
        <>
            {!isLoaded && <ScreenLoader />}
            <iframe
                frameBorder="0"
                width={window.innerWidth}
                height={window.innerHeight}
                onLoad={() => setIsLoaded(true)}
                className={`w-full h-min-screen bg-primary-main ${!isLoaded && "invisible h-0"}`}
                src={tournament?.game.gamePath}
                scrolling="no"
            ></iframe>
        </>
    )
}

export default TournamentPlayCanvas
