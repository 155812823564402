import { ScreenLoader } from "components/template"
import { useApp } from "core/providers/AppProvider"
import { useGame } from "core/providers/GameProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { ArrowLeft, VolumeUp } from "core/utilities/Icons"
import {
    Avatar1,
    Avatar2,
    Avatar3,
    Avatar4,
    Avatar5,
    Avatar6,
    Avatar7,
    Avatar8,
    Avatar9,
    AvatarUnknown,
    Gold,
    Silver,
    VersusPointerBlue,
    VersusPointerRed,
} from "core/utilities/ImageImport"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"

const Versus = () => {
    const navigate = useNavigate()
    const { currentUser } = useApp()
    const { onGoingGameData, loadingGame, game } = useGame()
    const [avatarSrc, setAvatarSrc] = useState(AvatarUnknown)
    const [isLoading, setIsLoading] = useState(true)
    const [countDown, setCountDown] = useState(3)
    const [showReward] = useState(true)
    const intervalRef = useRef<any>()
    const flipIntervalRef = useRef<any>()
    const [flipCount, setFlipCount] = useState<number>(10)
    const opponent =
        currentUser?.ID === onGoingGameData.playerOneId
            ? {
                  id: onGoingGameData.playerTwoId,
                  name: onGoingGameData.playerTwoName,
                  score: onGoingGameData.playerTwoScore,
                  avatar: onGoingGameData.playerTwoAvatar,
              }
            : {
                  id: onGoingGameData.playerOneId,
                  name: onGoingGameData.playerOneName,
                  score: onGoingGameData.playerOneScore,
                  avatar: onGoingGameData.playerOneAvatar,
              }

    const FLIP_DELAY_MS = 600
    const opponentContainer = useRef<HTMLDivElement>(null)
    const opponentAvatar = useRef<HTMLImageElement>(null)

    const flipElements = () => {
        if (opponentContainer.current?.classList.contains("flip")) {
            opponentContainer.current?.classList.remove("flip")
            opponentAvatar.current?.classList.remove("flip")
        } else {
            opponentContainer.current?.classList.add("flip")
            opponentAvatar.current?.classList.add("flip")
        }
    }

    useEffect(() => {
        if (flipCount === 0) {
            setAvatarSrc(AvatarUnknown)
            flipElements()
            return setIsLoading(false)
        }

        flipIntervalRef.current = setInterval(() => {
            setAvatarSrc(AvatarPictures[Math.round(Math.random() * (AvatarPictures.length - 1))])
            flipElements()
            setFlipCount(prev => prev - 1)
        }, FLIP_DELAY_MS)

        return () => clearInterval(flipIntervalRef.current)
    }, [flipCount])

    useEffect(() => {
        if (!isLoading) intervalRef.current = setInterval(() => setCountDown(prev => prev - 1), 1000)
        return () => clearInterval(intervalRef.current)
    }, [isLoading])

    useEffect(() => {
        if (countDown < 1) {
            if (onGoingGameData.game.engine === "unity")
                return navigate(`${AppRoutes.dashboard.game.play}/${game?.clientId}`, { replace: true })
            if (onGoingGameData.game.engine === "playcanvas")
                return navigate(`${AppRoutes.dashboard.game.playcanvas}/${game?.clientId}`, { replace: true })
        }
    }, [countDown])

    useEffect(() => {
        if (!game) return navigate(`${AppRoutes.dashboard.home}`)
    }, [])

    // Render
    if (loadingGame) return <ScreenLoader />
    return (
        <div className="flex flex-col h-screen">
            <div className="absolute top-4 z-50 w-full px-4">
                {showReward && (
                    <div className="flex justify-end items-center bg-slate-800  rounded-xl px-4 py-1.5">
                        {/* <VolumeUp /> */}
                        <div className="flex justify-between items-center">
                            <span className="text-base">جایزه برنده</span>
                            <span className="text-base mr-2 ml-1">{onGoingGameData.mode.rewards[0].amount}</span>

                            <img src={onGoingGameData.mode.rewards[0].type === 1 ? Silver : Gold} alt="" />
                        </div>
                    </div>
                )}
            </div>
            <div className="absolute -top-0 versus-bg-upper-shape bg-gradient-to-b from-gray-900 via-sky-500 to-sky-300 h-full w-full ltr overflow-hidden">
                <div className="absolute top-36 left-16 z-50 ">
                    <div className="bg-gradient-to-tr from-yellow-500 to-yellow-600 rounded-full w-32 h-32 p-1 animate-in-from-tl">
                        <img className="h-full w-full object-cover rounded-full " src={currentUser?.avatarUrl} alt="" />
                    </div>
                    <h3 className="text-white text-xl text-center mt-1">{currentUser?.name}</h3>
                </div>
                <img
                    src={VersusPointerBlue}
                    className="absolute  transition-all animate-in-from-tl -top-8 -left-16 scale-125"
                    alt=""
                />
            </div>
            <div className="flex relative justify-center items-center mx-auto my-auto bg-gradient-to-br from-rose-600 to-blue-500 rounded-full w-20 h-20 z-50 p-[1px]">
                <span className="absolute -z-10 rounded-full animate-ping bg-gradient-to-br from-rose-600 to-blue-500 w-full h-full"></span>
                <h2 className="flex justify-center items-center bg-gray-800 rounded-full text-white text-2xl w-5/6 h-5/6">
                    <span className="mt-1">VS</span>
                </h2>
            </div>
            <div className="absolute bottom-0 versus-bg-down-shape bg-gradient-to-t from-black via-rose-600 to-pink-600 h-full w-full ltr">
                <div className="absolute bottom-32 right-16 z-50">
                    <div
                        id="opponentContainer"
                        ref={opponentContainer}
                        className="bg-gradient-to-tr from-yellow-500 to-yellow-600 rounded-full w-32 h-32 p-1 animate-in-from-br transition-all duration-200"
                    >
                        <img
                            ref={opponentAvatar}
                            className={`h-full w-full object-cover rounded-full transition-all duration-200 ${
                                isLoading && "filter grayscale"
                            }`}
                            src={isLoading ? avatarSrc : opponent?.avatar ? opponent?.avatar : AvatarUnknown}
                            alt=""
                        />
                    </div>
                    <h3 className="text-white text-xl text-center mt-1">
                        {isLoading ? "?" : opponent?.name ? opponent?.name : "?"}
                    </h3>
                </div>
                <img
                    src={VersusPointerRed}
                    className="absolute -bottom-8 -right-16 animate-in-from-br object-cover scale-125"
                    alt=""
                />
            </div>
            {!isLoading && (
                <div className="flex flex-col z-50 absolute bottom-6 self-center">
                    <h3 className="text-white text-2xl text-center">{countDown}</h3>
                    <p className="text-white text-lg">ثانیه دیگه بازی شروع میشه</p>
                </div>
            )}
        </div>
    )
}

export default Versus

const AvatarPictures = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6, Avatar7, Avatar8, Avatar9]
