import { useState } from "react"
import { Button, Modal } from "antd"
import { ConfirmCode, LoginWithPhone } from "."
import { ChevronLeft, ChevronRight } from "core/utilities/Icons"
import { Trans } from "react-i18next"
import i18n from "../../../i18n"

const LoggedInAsGuestBanner = () => {
    // States and Hooks
    const [isOpen, setIsOpen] = useState<boolean>(false)

    // Render
    return (
        <div className="bg-primary-light mt-8 rounded-2xl p-4 ">
            <LoginWithPhoneNumberModal open={isOpen} onClose={() => setIsOpen(false)} />
            <h3 className="text-xl text-center text-white">
                <Trans>Logged in as guest</Trans>
            </h3>
            <div className="mt-6">
                <Button type="text" className="w-full h-8" shape="round" onClick={() => setIsOpen(true)}>
                    <div className="flex justify-between">
                        <span className="text-base ">
                            <Trans>Log in with phone number</Trans>
                        </span>
                        {i18n.language === "fa" ? (
                            <ChevronLeft className="w-4 h-4 my-auto" />
                        ) : (
                            <ChevronRight className="w-4 h-4 my-auto" />
                        )}
                    </div>
                </Button>
            </div>
        </div>
    )
}

interface ModalProps {
    open: boolean
    onClose: Function
}

type LoginWithPhoneStepType = "INPUT_NUMBER" | "CONFIRM_CODE"

const LoginWithPhoneNumberModal: React.FC<ModalProps> = ({ open, onClose }) => {
    // States and Hooks
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [step, setStep] = useState<LoginWithPhoneStepType>("INPUT_NUMBER")

    // Methods

    // Render
    return (
        <div className="custom-modal">
            <Modal open={open} onCancel={() => onClose()} className="custom-modal" centered footer={null}>
                {step === "INPUT_NUMBER" && (
                    <LoginWithPhone setStep={setStep} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
                )}
                {step === "CONFIRM_CODE" && <ConfirmCode setStep={setStep} phoneNumber={phoneNumber} />}
            </Modal>
        </div>
    )
}

export { LoggedInAsGuestBanner }
