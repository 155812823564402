import { Button, Image } from "antd"
import { Trans } from "react-i18next"
import { Layout } from "components/template/Layout"
import { EnglishFlag, PersianFlag } from "core/utilities/ImageImport"
import { useState } from "react"
import { ArrowLeft } from "core/utilities/Icons"
import { useApp } from "core/providers/AppProvider"
import i18n from "../i18n"
import { useNavigate } from "react-router"
import { AppRoutes } from "core/utilities/AppRoutes"
import { tools } from "core/functions/tools"

const ChooseLanguage = () => {
    // States and Hooks
    const { setLang } = useApp()
    const navigate = useNavigate()
    const [selectedLangIndex, setSelectedLangIndex] = useState<number>(0)

    // Methods
    const onConfirmButtonClick = () => {
        const language = Languages[selectedLangIndex].value
        setLang(Languages[selectedLangIndex].value)
        tools.setUserLang(language)
        i18n.changeLanguage(language)
        navigate(AppRoutes.dashboard.home)
    }

    // Render
    return (
        <Layout showHeader={false} showControlCapsule={false} fit={true}>
            <div className="english ltr">
                <div className="flex flex-col min-h-screen p-8">
                    <div className="flex items-center gap-2 ltr">
                        {<ArrowLeft className="rotate-180" />}
                        <span className="text-xl font-medium">
                            <Trans>Choose your language</Trans>
                        </span>
                    </div>

                    <div className="mt-10">
                        {Languages.map(({ flag, label }, index) => {
                            const isSelected = selectedLangIndex === index

                            return (
                                <div
                                    onClick={() => setSelectedLangIndex(index)}
                                    role="button"
                                    key={index}
                                    className={`rounded-2xl mb-5 ${
                                        isSelected
                                            ? "border border-solid border-green-500"
                                            : "border border-solid border-gray-100"
                                    }`}
                                >
                                    <div className="flex items-center justify-center py-5 gap-5">
                                        <Image src={flag} className="w-20 h-auto" preview={false} />
                                        <div
                                            className={`font-medium text-center mt-1 text-lg ${
                                                isSelected ? "text-green-500" : "text-gray-100"
                                            }`}
                                        >
                                            {label}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="mt-8">
                        <Button
                            type="link"
                            shape="round"
                            size="large"
                            className="text-center w-full h-12 border-gray-100 border-solid font-medium"
                            onClick={onConfirmButtonClick}
                        >
                            <span className="text-gray-100">Confirm and Continue</span>
                        </Button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ChooseLanguage

const Languages = [
    {
        value: "fa",
        flag: PersianFlag,
        label: "Persian",
    },
    {
        value: "en",
        flag: EnglishFlag,
        label: "English",
    },
]
