import { useApi } from "core/api/ApiProvider"
import { GameRankDataType, GameType, LeaderBoardType, UserGameModeType } from "core/api/types/GameTypes"
import { AppRoutes } from "core/utilities/AppRoutes"
import { GameErrors } from "core/utilities/ErrorIndicators"
import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useApp } from "./AppProvider"

type Props = {
    children: JSX.Element
}

type TabType = "LEADER_BOARD" | "GAME"

export const TabsObject: {
    game: TabType
    leaderBoard: TabType
} = {
    game: "GAME",
    leaderBoard: "LEADER_BOARD",
}

interface GameContextInterface {
    loadingGame: boolean
    leaderBoard: {
        total: LeaderBoardType[]
        close: LeaderBoardType[]
    }
    game: GameType | null
    trophy: number
    startGame: Function
    userGameModes: UserGameModeType[]
    highScore: number
    currentError: string | null
    clearError: () => void
    onGoingGameData: any
    tab: TabType
    setTab: Function

    loadingStartGameButton: boolean
    setLoadingStartGameButton: Function

    currentStartedGameRank: number
    setCurrentStartedGameRank: Function
    rankData: GameRankDataType | null

    fetchLeaderBoardData: Function
}

const initialContextValue: GameContextInterface = {
    loadingGame: true,
    game: null,
    trophy: 0,
    userGameModes: [],
    startGame: () => undefined,
    highScore: 0,
    currentError: null,
    clearError: () => undefined,
    onGoingGameData: null,
    tab: TabsObject.game,
    setTab: () => undefined,
    leaderBoard: { total: [], close: [] },

    loadingStartGameButton: false,
    setLoadingStartGameButton: () => undefined,

    currentStartedGameRank: 0,
    setCurrentStartedGameRank: () => undefined,
    rankData: null,

    fetchLeaderBoardData: () => undefined,
}

const GameContext = createContext<GameContextInterface>(initialContextValue)

export const useGame = () => useContext(GameContext)

const GameProvider: React.FC<Props> = ({ children }) => {
    // States and Hooks
    const navigate = useNavigate()
    const { games } = useApi()
    const [trophy, setTrophy] = useState<number>(0)
    const { currentUser, loadingApp } = useApp()
    const [userGameModes, setUserGameModes] = useState<UserGameModeType[]>([])
    const [highScore, setHighScore] = useState<number>(0)
    const [game, setGame] = useState<GameType | null>(null)
    const [rankData, setRankData] = useState<GameRankDataType | null>(null)
    const [onGoingGameData, setOnGoingGameData] = useState<any>(null)
    const { game_client_id: gameClientId } = useParams()
    const [currentError, setCurrentError] = useState<GameContextInterface["currentError"]>(null)
    const [leaderBoard, setLeaderBoard] = useState<GameContextInterface["leaderBoard"]>({ total: [], close: [] })
    const [isLeaderBoardLoading, setIsLeaderBoardLoading] = useState<boolean>(true)
    const loadingGame: boolean = game === null || isLeaderBoardLoading
    const [tab, setTab] = useState<TabType>(TabsObject.game)
    const [currentStartedGameRank, setCurrentStartedGameRank] = useState<number>(0)
    const [loadingStartGameButton, setLoadingStartGameButton] = useState<boolean>(false)

    // Methods
    const fetchGame = async () => {
        const { data, error } = await games.findOne({ gameClientId, ID: currentUser?.ID })
        if (!error) {
            setGame(data.userGame.game)
            setUserGameModes(
                data.userGame.userGameModes.sort(
                    (item1: UserGameModeType, item2: UserGameModeType) => item1.rank - item2.rank
                )
            )
            setHighScore(data.userGame.highScore)
            setTrophy(data.userGame.trophy)
            setRankData(data.rankData)
        }
    }

    const startGame = async (args: { rank: number; entranceFee: number }) => {
        // Error handling here.
        if (!currentUser) return
        if (currentUser.silverCoin < args.entranceFee) return setCurrentError(GameErrors.NOT_ENOUGH_COINS)

        setLoadingStartGameButton(true)
        const { data, error } = await games.start({ clientId: gameClientId, ID: currentUser?.ID, rank: args.rank })
        setLoadingStartGameButton(false)

        if (error) return setCurrentError(GameErrors.PROBLEM_STARTING)
        setOnGoingGameData(data)
        return navigate(AppRoutes.dashboard.game.versus)
    }

    const fetchLeaderBoardData = async (args: { size: number; page: number }) => {
        const { data, error } = await games.getLeaderBoard({
            ID: currentUser?.ID,
            gameId: gameClientId,
            size: args.size,
            page: args.page,
        })
        if (error) return setCurrentError(error)

        setIsLeaderBoardLoading(false)

        const total: LeaderBoardType[] | null = data.total
        const close: LeaderBoardType[] | null = data.close

        const newLeaderBoardData = leaderBoard

        if (total) newLeaderBoardData.total = [...newLeaderBoardData.total, ...total]
        if (close) newLeaderBoardData.close = [...newLeaderBoardData.close, ...close]

        setLeaderBoard(newLeaderBoardData)
        return total
    }

    const clearError = () => setCurrentError(null)

    // Binding
    const value = {
        // States
        game,
        trophy,
        highScore,
        loadingGame,
        userGameModes,
        currentError,
        onGoingGameData,
        tab,
        setTab,
        leaderBoard,

        loadingStartGameButton,
        setLoadingStartGameButton,

        currentStartedGameRank,
        setCurrentStartedGameRank,
        rankData,

        // Methods
        startGame,
        clearError,
        fetchLeaderBoardData,
    }

    useEffect(() => {
        if (!loadingApp) {
            fetchGame()
            fetchLeaderBoardData({ size: 20, page: 1 })
        }
    }, [loadingApp])

    // Render
    return <GameContext.Provider value={value}>{children}</GameContext.Provider>
}

export default GameProvider

export const RankToMedal = {
    2: "Bronze",
    3: "Silver",
    4: "Gold",
}

export const MedalTypeLocalization: { [key: string]: string } = {
    Qualification: "دست گرمی",
    Bronze: "برنز",
    Silver: "نقره",
    Gold: "طلا",
    Diamond: "الماس",
    Champion: "قهرمانان",
    Legend: "افسانه‌ای",
}
