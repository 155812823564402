import { FC } from "react"
import { Button, Input } from "antd"
import { Phone } from "core/utilities/ImageImport"
import { useAuth } from "core/providers/AuthProvider"
import { Link } from "react-router-dom"
import { AppRoutes } from "core/utilities/AppRoutes"

interface Props {}

export const LoginWithPhone: FC<Props> = ({}) => {
    // States and Hooks
    const { onLoginButtonClick, loginButtonLoading, phoneNumber, setPhoneNumber } = useAuth()

    // Methods

    // Render
    return (
        <>
            <div className="flex flex-col items-center">
                <h3 className="text-3xl text-white mb-2">ورود</h3>
                <div className="w-1/3 h-1 rounded-full bg-gradient-to-tr from-blue-500 to-sky-400"></div>
                <div className="flex justify-between w-full mt-8">
                    <h2 className="text-xl text-white">ورود با شماره همراه</h2>
                    <img src={Phone} className="w-6 h-6" alt="phone-icon" />
                </div>
                <Input
                    type="tel"
                    pattern="[0-9]*"
                    placeholder="شماره موبایل خود را وارد کنید"
                    value={phoneNumber}
                    className="text-white mt-5 px-4 py-3 border-white border border-solid bg-transparent rounded-xl w-full focus:outline-none text-center"
                    onChange={e => setPhoneNumber(e.target.value)}
                />
                <Button
                    className={`w-full bg-gradient-to-tr ${
                        loginButtonLoading ? "from-gray-600  to-gray-400" : "from-blue-600  to-sky-400"
                    } border-0 rounded-xl h-12 mt-4 text-2xl`}
                    type="primary"
                    disabled={loginButtonLoading}
                    onClick={() => onLoginButtonClick()}
                >
                    تایید
                </Button>
            </div>
            <div className="text-start underline text-blue-500 mt-2">
                <Link to={AppRoutes.privacyPolicy}>حریم خصوصی</Link>
            </div>
        </>
    )
}
