import {
    ClockDeactive,
    ShopDeactive,
    ShopActive,
    ClockActive,
    HomeActive,
    HomeDeactive,
} from "core/utilities/ImageImport.js"
import { Link, useLocation } from "react-router-dom"
import { AppRoutes } from "core/utilities/AppRoutes"
import { useLayoutEffect, useState } from "react"
import { BlurBg } from "./BlurBg"

const ControlCapsule: React.FC = () => {
    const [activeIcon, setActiveIcon] = useState<"Home" | "Clock" | "Shop">("Home")

    const location = useLocation()

    useLayoutEffect(() => {
        location.pathname === AppRoutes.dashboard.shop.home && setActiveIcon("Shop")
        location.pathname === AppRoutes.dashboard.activity && setActiveIcon("Clock")
    }, [location.pathname])

    const shopIconFile = activeIcon === "Shop" ? ShopActive : ShopDeactive
    const clockIconFile = activeIcon === "Clock" ? ClockActive : ClockDeactive
    const homeIconFile = activeIcon === "Home" ? HomeActive : HomeDeactive

    return (
        <div className={`fixed w-max bottom-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40`}>
            <BlurBg className="bg-slate-700 bg-opacity-60 rounded-full backdrop-brightness-95 z-10" />
            <div className="flex relative z-10 gap-8 px-8 py-2">
                <Link to={AppRoutes.dashboard.shop.home} className="p-2">
                    <img src={shopIconFile} alt="Home" className="w-6 h-6" />
                </Link>
                <Link to={AppRoutes.dashboard.home} className="p-2">
                    <img src={homeIconFile} alt="Home" className="w-6 h-6" />
                </Link>
                <Link to={AppRoutes.dashboard.activity} className="p-2">
                    <img src={clockIconFile} className="w-6 h-6" alt="" />
                </Link>
            </div>
        </div>
    )
}

export { ControlCapsule }
