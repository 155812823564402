export const Content = {
    en: {
        translations: {
            Back: "Back",
            "Recent Games": "Recent Games",
            Start: "Start",
            Tournaments: "Tournaments",
            "Prize Pool": "Prize Pool",
            "Enter Tournament": "Enter Tournament",
            Entrance: "Entrance",
            "Starts In": "Starts In",
            Hours: "Hours",
            Minutes: "Minutes",
            Seconds: "Seconds",

            "First Place": "First place",
            "Second Place": "Second place",

            Reward: "Reward",

            "Game Name": "Game name",
            "Game Mode": "Game mode",
            "Game ID": "Game ID",

            Time: "Time",

            Report: "Report",

            "Ends In": "Ends in",

            "Win Gold Coins": "Win Gold Coins",
            "Invite Friends": "Invite Friends",
            "Invite Friends Body": "Invite Friends and Earn Coins",
            "Your Invitation Code": "Your Invitation Code",
            "Enter Gift Code": "Enter Gift Code",
            "Enter Invitation Code": "Enter Invitation Code",
            "Enter Code": "Enter Code",
            Confirm: "Confirm",

            "Profile Settings": "Profile Settings",
            "General Settings": "General Settings",
            Nickname: "Nickname",
            "Change Nickname": "Change Your Nickname",
            "Required Coins": "Required Coins",
            "Go to Store": "Go to Store",
            Exit: "Exit",
            "Are You Sure?": "Are You Sure?",
            "Guest Login Exit": "You Have Logged in as a Guest, Your Progress May Be Lost After Exit!",
            Cancel: "Cancel",
            "Logged in as guest": "Logged in as guest",
            "Log in with phone number": "Log in with phone number",
            "Main Games": "Main Games",
            Bouncy: "Bouncy",
            Hexonix: "Hexonix",
            "2048 Cubes": "Bouncy",
            Jumpy: "Jumpy",
            "Tetris 3*3": "Tetris 3*3",
            "Jelly Wars": "Jelly Wars",

            Game: "Game",

            "Recent Activity": "Recent Activity",
            Unfinished: "Unfinished",
            "Waiting Opponent": "Waiting for Opponent",
            Trial: "Trial Phase",
            "Unlocks After Trial": "Unlocks After Trial Phase",
            "Practice Game": "Practice Game",
            "You Placed 2nd": "You Placed 2nd",
            "You Won": "You Won!",
            Duel: "Duel",
            Coins: "Coins",
            Coin: "Coin",

            Packs: "Packs",
            Price: "Toman",

            "Select Avatar": "Select Avatar",

            "Convert Coins": "Convert Gold Coins Into Silver Coins",
            "Gold Coin": "Gold Coin",
            "Silver Coin": "Silver Coin",
            Convert: "Convert",
            "Not Enough Coins": "Not Enough Coins",

            "Loading The Game": "Loading The Game",

            "Games Stats": "Games Stats",

            "You Placed": "You placed {{rank}}",

            "Redirecting To The Match": "Redirecting to the match",

            Play: "Play",
            "Duel With": "Duel With Opponents and Show Them Your Skills!",
            "Winner Prize": "Winner Prize",
            "Start Match": "Start Match",
            "Unlocks in": "Unlocks in <0></0>",
            Practice: "Practice",
            "Practice Description": "Practice to Improve Your Skills",
            "Your Stats": "Your Stats",
            "Your Scores": "Your Scores",
            "High Score": "High Score",
            "Match Start": "Match Starts in:",

            Leaderboard: "Leaderboard",
            "Seasonal Leaderboard": "Seasonal Leaderboard",
            "Leaderboard Description":
                "Compete with All Players and Earn Coins. Leaderboard Will Restart After Season Ends.",
            "Full List": "Full List",

            "One To One": "1 to 1",

            Qualification: "Qualification",
            Bronze: "Bronze",
            Silver: "Silver",
            Gold: "Gold",
            Diamond: "Diamond",
            Champion: "Champion",
            Legend: "Legend",

            "Loading Game Issue": "The Game did not load",
            "Lost Internet Issue": "Internet got disconnected",
            "Game Locked Or Closed Issue": "Game got locked or closed",
            "Game Bug Issue": "There was a bug in the game",
            "Score Not Saved Issue": "Scores were not saved",
            "No Opponent Issue": "Could not find an opponent",
            "Lost Reward Issue": "Did not receive the reward",
            "Expired Game Issue": "Game Expired",
            "Other Issues": "Other issues",

            Login: "Login",
            "Login with Phone Number": "Login with Phone Number",
            "Enter your phone number": "Enter your phone number",
            Tournament: "Tournament",
        },
    },
    fa: {
        translations: {
            "Recent Games": "بازی‌های اخیر",

            "First Place": "اول",
            "Second Place": "دوم",
            "Your Invitation Code": "کد دعوت شما",
            "Logged in as guest": "به عنوان مهمان وارد شده اید!",
            "Log in with phone number": "ورود با شماره موبایل",
            "Enter Gift Code": "وارد کردن کد هدیه",
            "Enter Invitation Code": "وارد کردن کد دعوت",
            "Enter Code": "کد را وارد کنید",
            Report: "گزارش",

            "Game Name": "نام بازی",
            "Game Mode": "مود بازی",
            "Game ID": "شناسه بازی",

            Reward: "جایزه",

            Time: "زمان",

            "Ends In": "تا پایان",

            Nickname: "نام مستعار",
            "Change Nickname": "تغییر نام مستعار",
            "Required Coins": "سکه مورد نیاز",

            Tournaments: "تورنومنت‌ها",
            "Prize Pool": "مجموع جوایز",
            "Enter Tournament": "ورود به تورنومنت",
            Entrance: "ورودی",
            "Starts In": "تا شروع",
            Hours: "ساعت",
            Minutes: "دقیقه",
            Seconds: "ثانیه",

            "You Placed": "نفر {{rank}} ام شدید",

            "Main Games": "بازی‌های اصلی",
            Bouncy: "توپ شیطونک",
            Hexonix: "هگزونیکس",
            "2048 Cubes": "۲۰۴۸ مکعبی",
            Jumpy: "جامپی",
            "Tetris 3*3": "تتریس ۳*۳",
            "Jelly Wars": "جنگ ژله‌ای",

            "Win Gold Coins": "سکه طلایی ببر",
            "Invite Friends": "دعوت از دوستان",
            "Invite Friends Body": "دوستات رو به بازی دعوت کن و جایزه بگیر",

            "Redirecting To The Match": "در حال انتقال به مسابقه",

            "Recent Activity": "فعالیت‌های اخیر",
            Unfinished: "در حال انجام",
            "Waiting Opponent": "منتظر بازی حریف",
            Trial: "دست گرمی",
            "Unlocks After Trial": "بعد از اتمام دست گرمی باز می شود",
            "Practice Game": "بازی تمرینی",
            "You Placed 2nd": "نفر دوم شدی",
            "You Won": "برنده شدی!",
            Duel: "دوئل",
            Coins: "سکه‌ای",
            Coin: "سکه",

            Packs: "بسته‌ها",
            Price: "تومان",
            "Convert Coins": "تبدیل سکه نقره‌ای به طلایی",
            "Gold Coin": "سکه طلایی",
            "Silver Coin": "سکه نقره‌ای",
            Convert: "تبدیل",
            "Not Enough Coins": "سکه کافی ندارید",

            "Select Avatar": "انتخاب آواتار",

            Play: "بازی",
            "Duel With": "با حریف‌هات بازی کن و توانایی‌هات رو به رخ بکش!",
            "Winner Prize": "جایزه برنده",
            "Start Match": "شروع بازی",
            "Unlocks in": "در سطح <0></0> باز می‌شود",
            Practice: "تمرین کن",
            "Practice Description": "بازی کن تا در رقابت اصلی بهتر شرکت کنی",
            "Your Stats": "آمار شما",
            "Games Stats": "آمار بازی ها",
            "Your Scores": "امتیاز های شما",
            "High Score": "بیشترین امتیاز",
            "Match Start": "ثانیه دیگه بازی شروع میشه",

            "Profile Settings": "تنظیمات پروفایل",
            "General Settings": "عمومی",

            "Loading The Game": "در حال بارگزاری بازی",

            Back: "بازگشت",

            Leaderboard: "لیدربورد",
            "Seasonal Leaderboard": "لیدربورد فصلی",
            "Leaderboard Description":
                "با تمام بازیکن‌ها به صورت هفتگی رقابت کن و جایزه ببر. توجه کن که در هر فصل امتیازها از صفر شروع می‌شوند",
            "Full List": "لیست کامل",
            "Go to Store": "فروشگاه",

            "One To One": "1 به 1",

            Game: "بازی",

            Start: "شروع",

            Confirm: "تایید",
            Cancel: "لغو",
            Exit: "خروج",

            Qualification: "دست گرمی",
            Bronze: "برنز",
            Silver: "نقره",
            Gold: "طلا",
            Diamond: "الماس",
            Champion: "قهرمانان",
            Legend: "افسانه‌ای",

            "Loading Game Issue": "بازی لود نشد.",
            "Lost Internet Issue": "اینترنت قطع شد",
            "Game Locked Or Closed Issue": "بازی قفل یا بسته شد.",
            "Game Bug Issue": "بازی باگ داشت.",
            "Score Not Saved Issue": "امتیاز ذخیره نشد.",
            "No Opponent Issue": "حریف پیدا نشد.",
            "Lost Reward Issue": "جایزه داده نشد.",
            "Expired Game Issue": "بازی منقضی شد.",
            "Other Issues": "دیگر",

            Login: "ورود",
            "Login with Phone Number": "ورود با شماره موبایل",
            "Enter your phone number": "شماره خود را وارد کنید",
            Tournament: "تورنومنت",
        },
    },
}
