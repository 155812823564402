import React, { useEffect, useState } from "react"
import { Avatar, Button } from "antd"
import { useNavigate } from "react-router"

// Utilities
import { AppRoutes } from "core/utilities/AppRoutes"
import { CheckIcon, ChevronLeft, ChevronRight } from "core/utilities/Icons"
import { ClockActive, Cup, Gold, Silver } from "core/utilities/ImageImport"

// Providers
import { useApp } from "core/providers/AppProvider"
import { useTournament } from "core/providers/TournamentProvider"

// Functions
import { tools } from "core/functions/tools"

// Interface & Types
import { TournamentType } from "core/api/types/TournamentTypes"
import { NotEnoughCoinsModal } from "components/game/NotEnoughCoinsModal"
import { Trans, useTranslation } from "react-i18next"
interface Props {
    tournament: TournamentType | null
}

const Qualification: React.FC<Props> = ({ tournament }) => {
    // States and Hooks
    const navigate = useNavigate()
    const prizePools = tournament?.prizePools
    const activePrizePool = prizePools?.find(_ => _.players < (tournament?.activePlayers.playCount ?? 0))
    // States and Hooks
    const [isNotEnoughCoinsModalOpen, setIsNotEnoughCoinsModalOpen] = useState<boolean>(false)
    const { startTournament } = useTournament()
    const { setCurrentTournament, currentUser } = useApp()
    const [loading, setLoading] = useState<boolean>(false)
    const { t, i18n } = useTranslation()

    // Methods
    const calculateTime = (endTime: any) => {
        const startTimeUnix = new Date().getTime()
        const endTimeUnix = new Date(endTime).getTime()
        const actualTime = new Date(endTimeUnix - startTimeUnix)

        return {
            hours: actualTime.getHours(),
            minutes: actualTime.getMinutes(),
            seconds: actualTime.getSeconds(),
        }
    }
    const onStartButtonClick = async () => {
        setLoading(true)
        setCurrentTournament(tournament)
        const hasEnoughFund = !(
            (tournament?.entranceType === 1 && (currentUser?.silverCoin ?? 0) < Number(tournament.entrance)) ||
            (tournament?.entranceType === 0 && (currentUser?.goldCoin ?? 0) < Number(tournament.entrance))
        )
        if (!hasEnoughFund) {
            setIsNotEnoughCoinsModalOpen(true)
            return setLoading(false)
        }

        if (hasEnoughFund)
            if (currentUser?.silverCoin)
                await startTournament({
                    tournamentID: tournament?.tournamentId,
                    entranceFee: tournament?.entrance,
                })

        setLoading(false)
    }

    const [time, setTime] = useState<any>(calculateTime(tournament?.endTime))

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(calculateTime(tournament?.endTime))
        }, 1000)

        return () => clearInterval(timer)
    }, [])

    // Render
    return (
        <>
            {isNotEnoughCoinsModalOpen && (
                <div className="fixed left-0 top-0 z-50 w-full h-full">
                    <NotEnoughCoinsModal onClose={() => setIsNotEnoughCoinsModalOpen(false)} />
                </div>
            )}
            <div className="flex items-center w-full px-6 py-4 mt-4 bg-black bg-opacity-40 backdrop-blur-sm rounded-3xl">
                <div className="w-full">
                    {/* Header */}
                    <div className="flex items-center justify-between">
                        <div className="text-lg font-semibold">{tournament?.game?.name}</div>
                        <div className="flex items-center justify-between gap-2">
                            <div className="flex h-full">
                                <span className="text-[10px]">
                                    <Trans>Prize Pool</Trans>
                                </span>
                            </div>
                            <div className="flex items-center justify-between gap-x-2">
                                <div className="text-xl">
                                    {tools.thousandsSeparator(
                                        tournament?.prizePools[tournament?.prizePools.length - 1].reward.amount ?? 0
                                    )}
                                </div>
                                <div>
                                    <img
                                        className="w-7"
                                        alt="mode"
                                        src={tournament?.prizePools[0].reward.type === 1 ? Silver : Gold}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Content */}
                    <div className="flex items-center justify-between py-6 gap-x-5">
                        {/* TODO: Refactor to use existing StepIndicator */}
                        <div
                            onClick={() =>
                                navigate(`${AppRoutes.dashboard.tournament.statistics}/${tournament?.tournamentId}`)
                            }
                            className="w-full h-2 bg-gray-500 flex items-center relative cursor-pointer rounded-full"
                        >
                            <div
                                className={`h-2 from-[#4BF05C] to-[#A5EB4B] rounded-full bg-gradient-to-r left-0 absolute`}
                                style={{
                                    width: `${
                                        ((tournament?.progress.value ?? 0) / (tournament?.progress.maxValue ?? 1)) *
                                            100 ?? 0
                                    }%`,
                                }}
                            />
                            <span
                                className={`p-[2px] rounded-full bg-gradient-to-r from-green-500 to-green-400 absolute left-0 ${
                                    ((prizePools && prizePools[0]?.players) ?? 0) >
                                        (tournament?.activePlayers.playCount ?? 0) && "grayscale"
                                }`}
                            >
                                <CheckIcon />
                            </span>
                            <span
                                className={`p-[2px] rounded-full bg-gradient-to-r from-green-500 to-green-400 absolute left-1/2 -translate-x-1/3 ${
                                    ((prizePools && prizePools[1]?.players) ?? 0) >
                                        (tournament?.activePlayers.playCount ?? 0) && "grayscale"
                                }`}
                            >
                                <CheckIcon />
                            </span>
                            <span
                                className={`p-[2px] rounded-full bg-gradient-to-r from-green-500 to-green-400 absolute right-0 ${
                                    ((prizePools && prizePools[2]?.players) ?? 0) >
                                        (tournament?.activePlayers.playCount ?? 0) && "grayscale"
                                }`}
                            >
                                <CheckIcon />
                            </span>
                        </div>
                        <div className="flex items-center gap-x-1">
                            <div>{tools.thousandsSeparator(tournament?.activePlayers.playCount ?? 0)}</div>
                            <Avatar.Group>
                                {tournament?.activePlayers?.avatars?.map((src: any, index: number) => (
                                    <Avatar size="small" key={`title-avatar-${index}`} src={src} />
                                ))}
                            </Avatar.Group>
                            {i18n.language === "fa" ? (
                                <ChevronLeft className="w-3 h-3" />
                            ) : (
                                <ChevronRight className="w-3 h-3" />
                            )}
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="flex flex-col mt-1 gap-y-4">
                        <div className="flex items-center justify-around">
                            <div className="flex items-start gap-x-2">
                                <img className="w-4" alt="mode" src={ClockActive} />
                                <span>{`
                            ${time?.hours}
                             ${t("Hours")}
                             ${time?.minutes} ${t("Minutes")} ${time?.seconds} ${t("Seconds")}
                            `}</span>
                            </div>
                            <div className="flex items-start gap-x-2">
                                <img className="w-4" alt="mode" src={Cup} />
                                <div>{tools.thousandsSeparator(activePrizePool?.rewardedPlayers ?? 0)}</div>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            onClick={onStartButtonClick}
                            className="flex items-center justify-center gap-1.5 bg-gradient-to-tr from-[#8629CF] via-[#7c50ff] to-[#50CAFF] border-0 rounded-2xl h-12 text-lg w-full hover:from-[#7a22be] hover:via-[#6538eb] hover:to-[#5093ff] hover:bg-gradient-to-tr"
                            loading={loading}
                            disabled={loading}
                        >
                            <div className="flex items-center gap-x-2">
                                <span className="text-base">{tournament?.entrance}</span>
                                <img className="w-7" alt="mode" src={tournament?.entranceType === 1 ? Silver : Gold} />
                                <span>
                                    <Trans>Enter Tournament</Trans>
                                </span>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Qualification }
