import { Col, Row, Skeleton } from "antd"
import { tools } from "core/functions/tools"
import { Gold, Silver } from "core/utilities/ImageImport.js"
interface Props {
    gameImage: string
    title: string
    desc: string
    score?: {
        type: "Gold" | "Silver" | "Bronze"
        amount: number
    }
    className?: React.HTMLProps<HTMLDivElement>["className"]
}

interface IActivityCard extends React.FC<Props> {
    Skeleton: React.FC<{}>
}

const ActivityCard: IActivityCard = ({ gameImage, title, desc, score, className }) => {
    return (
        <Row align="middle" className={`bg-primary-light rounded-3xl p-3 ${className}`}>
            <Col span={4}>
                <img className="object-cover w-full aspect-square rounded-xl" src={gameImage} alt="" />
            </Col>
            <Col span={13} push={1}>
                <h3 className="text-white text-lg">{title}</h3>
                <span className="text-gray-300 text-xs">{desc}</span>
            </Col>
            {score && (
                <Col span={6}>
                    <div className="flex justify-center items-center gap-2 w-full rounded-full px-5 py-1 bg-gradient-to-r from-yellow-500 to bg-yellow-400 ltr">
                        <span className="text-base">{tools.thousandsSeparator(score.amount)}</span>
                        <img className="w-6 h-6" src={score.type === "Gold" ? Gold : Silver} alt="reward" />
                    </div>
                </Col>
            )}
        </Row>
    )
}

ActivityCard.Skeleton = () => (
    <div className="flex items-center gap-2 bg-slate-600 rounded-3xl p-3">
        <Col span={4}>
            <Skeleton.Button size="large" shape="square" className="activity-card-icon" active />
        </Col>

        <div className="flex flex-col w-full">
            <div className="w-1/2">
                <Skeleton.Button size="small" active block />
            </div>
            <div className="w-1/4">
                <Skeleton.Button className="mt-1" size="small" active block />
            </div>
        </div>
    </div>
)

export { ActivityCard }
