import React from "react"

// Utilities
import { Gold, Silver, User, UserCup } from "core/utilities/ImageImport"
import { useTournament } from "core/providers/TournamentProvider"
import { tools } from "core/functions/tools"

// Interface
interface Props {
    statistics: any[]
}

const StatisticsList: React.FC<Props> = ({ statistics }) => {
    // States and Hooks
    const { tournament } = useTournament()
    const prizePools = tournament?.prizePools
    const activePrizePool = prizePools?.find(_ => _.players < (tournament?.activePlayers.playCount ?? 0))

    // Render
    return (
        <div className="flex flex-col gap-y-4">
            {prizePools?.map((_, index) => {
                const nextPrizePool = prizePools[index + 1]
                return (
                    <div>
                        <div
                            className={`flex items-center justify-between w-full bg-gradient-to-r from-[#10E6B2] to-[#D2F529] p-4 rounded-3xl ${
                                !(activePrizePool?.players === _.players) && "opacity-50"
                            }`}
                        >
                            <div className="flex flex-col items-center gap-y-1">
                                <span className="text-black">جایزه بگیران</span>
                                <div className="flex items-center gap-x-1">
                                    <span className="text-base text-black">
                                        {tools.thousandsSeparator(_.rewardedPlayers)}
                                    </span>
                                    <img className="w-5" alt="cup" src={UserCup} />
                                </div>
                            </div>
                            <div className="flex flex-col items-center gap-y-1">
                                <span className="text-black">مجموع جوایز</span>
                                <div className="flex items-center gap-x-1">
                                    <span className="text-base text-black">
                                        {tools.thousandsSeparator(_.reward.amount)}
                                    </span>
                                    <img className="w-5" alt="cup" src={_.reward.type === 1 ? Silver : Gold} />
                                </div>
                            </div>
                            <div className="flex flex-col items-center gap-y-1">
                                <span className="text-black">بازیکن‌ها</span>
                                <div className="flex items-center gap-x-1">
                                    <span className="text-base text-black">
                                        {!nextPrizePool && "+"}
                                        {_.players}
                                        {nextPrizePool && `-${nextPrizePool.players - 1}`}
                                    </span>
                                    <img className="w-5" alt="cup" src={User} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export { StatisticsList }
