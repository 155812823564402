import Home from "pages/index"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import Profile from "pages/profile"
import Settings from "pages/settings"
import AppProvider, { useApp } from "core/providers/AppProvider"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { AppRoutes } from "core/utilities/AppRoutes"
import InviteFriends from "pages/invite-friends"
import Awaiting from "pages/awaiting"
import Win from "pages/win"
import Lose from "pages/lose"
import Game from "pages/game"
import { ToastContainer } from "react-toastify"
import GameUserProgress from "pages/game-user-progress"
import Activity from "pages/activity"
import { ConfigProvider } from "antd"
import ScrollToTop from "core/utilities/ScrollToTop"
import { useState, useEffect } from "react"
import OfflineModal from "components/template/OfflineModal"
import Login from "pages/login"
import { ProtectedRoute } from "components/template/ProtectedRoute"
import ApiProvider from "core/api/ApiProvider"
import HomeProvider from "core/providers/HomeProvider"
import Play from "pages/game/play"
import GameProviderLayout from "core/providers/layouts/GameProviderLayout"
import TournamentProviderLayout from "core/providers/layouts/TournamentProviderLayout"
import ActivityProvider from "core/providers/ActivityProvider"
import Versus from "pages/versus"
import Onboarding from "pages/game/onboarding"
import ConfirmCode from "pages/confirm-code"
import AuthProviderLayout from "core/providers/layouts/AuthProviderLayout"
import ShopVerify from "pages/shop/verify"
import Shop from "pages/shop"
import ShopProviderLayout from "core/providers/layouts/ShopProviderLayout"
import EnterName from "pages/enter-name"
import PlayCanvas from "pages/game/playcanvas"
import { withTranslation } from "react-i18next"

import metrix from "./metrix"
import { SafariInstallModal } from "components/template/SafariInstallModal"
import { isSafari } from "react-device-detect"
import ProfileProviderLayout from "core/providers/layouts/ProfileProviderLayout"
import UserProfile from "pages/user-profile"
import UserProfileProvider from "core/providers/UserProfileProvider"
import PrivacyPolicy from "pages/privacy-policy"
import GameLeaderBoard from "pages/game-leader-board"
import Android from "pages/android"

// Tournament
import GameTournament from "pages/tournament/game"
import VersusTournament from "pages/tournament/versus"
import StatisticsTournament from "pages/tournament/statistics"
import TournamentPlayCanvas from "pages/tournament/playcanvas"
import TournamentLeaderBoard from "pages/tournament/leaderboard"
import ResultTournament from "pages/tournament/result"
import ChooseLanguage from "pages/choose-language"
import i18n from "./i18n"

// Adding Firebase Analytics to the project
const firebaseConfig = {
    apiKey: "AIzaSyBnqfQkMmKF10F_FIyIEpodzcnDEZaLCGQ",
    authDomain: "funcraft-12f0f.firebaseapp.com",
    projectId: "funcraft-12f0f",
    storageBucket: "funcraft-12f0f.appspot.com",
    messagingSenderId: "872075824207",
    appId: "1:872075824207:web:98f435680d825c7f572e3a",
    measurementId: "G-4WBH7XQWCH",
}

const App = () => {
    // Initializations
    const { REACT_APP_METRIX_APP_ID } = process.env
    metrix.initialize({
        appId: REACT_APP_METRIX_APP_ID,
    })

    const app = initializeApp(firebaseConfig)
    getAnalytics(app)

    // States and Hooks
    const [isOnline, setIsOnline] = useState<boolean>(true)
    const [showSafariInstallBanner, setShowSafariInstallBanner] = useState(false)
    const isPWAInstalled = window.matchMedia("(display-mode: fullscreen)").matches

    // Methods

    useEffect(() => {
        window.addEventListener("offline", () => {
            setIsOnline(false)
        })
        window.addEventListener("online", () => {
            setIsOnline(true)
        })
        window.addEventListener("backbutton", () => {}, false)

        if (isSafari && !isPWAInstalled) setShowSafariInstallBanner(true)

        return () => {
            window.removeEventListener("offline", () => {
                setIsOnline(false)
            })
            window.removeEventListener("backbutton", () => {}, false)
            window.removeEventListener("online", () => {
                setIsOnline(true)
            })
        }
    }, [])

    // Render
    return (
        <ApiProvider>
            <div className={i18n.language === "fa" ? "persian everything-rtl" : "english everything-ltr"}>
                <OfflineModal isOpen={!isOnline} />
                <ToastContainer autoClose={2000} pauseOnFocusLoss={false} theme="dark" position="top-left" />
                <AppProvider>
                    <ConfigProvider direction={i18n.language === "fa" ? "rtl" : "ltr"}>
                        {showSafariInstallBanner && (
                            <SafariInstallModal onClose={() => setShowSafariInstallBanner(false)} />
                        )}
                        <Router>
                            <ScrollToTop />
                            <Routes>
                                {/* Public Routes */}
                                <Route path={AppRoutes.android} element={<Android />} />
                                <Route path={AppRoutes.chooseLanguage} element={<ChooseLanguage />} />
                                <Route element={<AuthProviderLayout />}>
                                    <Route path={AppRoutes.login} element={<Login />} />
                                    <Route path={AppRoutes.confirmCode} element={<ConfirmCode />} />
                                    <Route path={AppRoutes.enterName} element={<EnterName />} />
                                    <Route path={AppRoutes.privacyPolicy} element={<PrivacyPolicy />} />
                                </Route>
                                {/* Protected Routes */}
                                <Route
                                    path={AppRoutes.dashboard.home}
                                    element={
                                        <ProtectedRoute>
                                            <HomeProvider>
                                                <Home />
                                            </HomeProvider>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={AppRoutes.dashboard.inviteFriends}
                                    element={
                                        <ProtectedRoute>
                                            <InviteFriends />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route element={<ProfileProviderLayout />}>
                                    <Route
                                        path={AppRoutes.dashboard.profile}
                                        element={
                                            <ProtectedRoute>
                                                <Profile />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.userProfile}/:user_id`}
                                        element={
                                            <ProtectedRoute>
                                                <UserProfileProvider>
                                                    <UserProfile />
                                                </UserProfileProvider>
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={AppRoutes.dashboard.settings}
                                        element={
                                            <ProtectedRoute>
                                                <Settings />
                                            </ProtectedRoute>
                                        }
                                    />
                                </Route>
                                <Route
                                    path={AppRoutes.dashboard.awaiting + "/:match_id"}
                                    element={
                                        <ProtectedRoute>
                                            <ActivityProvider>
                                                <Awaiting />
                                            </ActivityProvider>
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path={AppRoutes.dashboard.win + "/:match_id"}
                                    element={
                                        <ProtectedRoute>
                                            <ActivityProvider>
                                                <Win />
                                            </ActivityProvider>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={AppRoutes.dashboard.lose + "/:match_id"}
                                    element={
                                        <ProtectedRoute>
                                            <ActivityProvider>
                                                <Lose />
                                            </ActivityProvider>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${AppRoutes.dashboard.tournament.result}/:tournament_client_id`}
                                    element={
                                        <ProtectedRoute>
                                            <ActivityProvider>
                                                <ResultTournament />
                                            </ActivityProvider>
                                        </ProtectedRoute>
                                    }
                                />

                                {/* Game routes will go here! */}
                                <Route element={<GameProviderLayout />}>
                                    <Route
                                        path={AppRoutes.dashboard.game.versus}
                                        element={
                                            <ProtectedRoute>
                                                <Versus />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.game.home}/:game_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <Game />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.game.progress}/:game_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <GameUserProgress />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.game.play}/:game_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <ActivityProvider>
                                                    <Play />
                                                </ActivityProvider>
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.game.onboarding}/:game_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <ActivityProvider>
                                                    <Onboarding />
                                                </ActivityProvider>
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.game.playcanvas}/:game_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <PlayCanvas />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.game.leaderBoard}/:game_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <GameLeaderBoard />
                                            </ProtectedRoute>
                                        }
                                    />
                                </Route>
                                <Route
                                    path={AppRoutes.dashboard.activity}
                                    element={
                                        <ProtectedRoute>
                                            <ActivityProvider>
                                                <Activity />
                                            </ActivityProvider>
                                        </ProtectedRoute>
                                    }
                                />

                                {/* Payment and Shop Routes Will Go Here! */}
                                <Route element={<ShopProviderLayout />}>
                                    <Route
                                        path={AppRoutes.dashboard.shop.home}
                                        element={
                                            <ProtectedRoute>
                                                <Shop />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.shop.verify}/:options`}
                                        element={
                                            <ProtectedRoute>
                                                <ShopVerify />
                                            </ProtectedRoute>
                                        }
                                    />
                                </Route>

                                {/* Tournaments Routes Will Go Here! */}
                                <Route element={<TournamentProviderLayout />}>
                                    <Route
                                        path={`${AppRoutes.dashboard.tournament.game.home}/:tournament_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <GameTournament />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.tournament.versus}/:tournament_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <VersusTournament />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.tournament.playcanvas}/:tournament_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <TournamentPlayCanvas />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.tournament.leaderBoard}/:tournament_client_id`}
                                        element={
                                            <ProtectedRoute>
                                                <TournamentLeaderBoard />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path={`${AppRoutes.dashboard.tournament.statistics}/:tournament_client_id`}
                                        element={<StatisticsTournament />}
                                    />
                                </Route>

                                <Route path="*" element={<Navigate to={AppRoutes.dashboard.home} />} />
                            </Routes>
                        </Router>
                    </ConfigProvider>
                </AppProvider>
            </div>
        </ApiProvider>
    )
}

export default withTranslation()(App)
