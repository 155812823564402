import { ScreenLoader } from "components/template"
import { useApi } from "core/api/ApiProvider"
import { useApp } from "core/providers/AppProvider"
import { useGame } from "core/providers/GameProvider"
import { AppRoutes } from "core/utilities/AppRoutes"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"

type Response = {
    score: number
    client_id: string
    client_secret: string
}

const PlayCanvas = () => {
    // States and Hooks
    const { games } = useApi()
    const navigate = useNavigate()
    const { currentUser, fetchUser } = useApp()
    const { loadingGame, onGoingGameData, game } = useGame()
    const [isLoaded, setIsLoaded] = useState(false)

    // Methods
    const handleEndMatch = async (event: any) => {
        if (event.data.event === "end-game-msg") {
            const result: Response = event.data.data

            const { data, error } = await games.endMatch({
                ID: currentUser?.ID,
                clientId: result.client_id,
                matchId: onGoingGameData.matchId,
                score: result.score,
                secret: result.client_secret,
            })
            await fetchUser()

            if (error) return toast.error("عملیاتت نا‌موفق")

            if (data.playerTwoEndGame && data.playerOneEndGame) {
                if (data.winnerId === currentUser?.ID) navigate(`${AppRoutes.dashboard.win}/${data.matchId}`)
                else navigate(`${AppRoutes.dashboard.lose}/${data.matchId}`)
            } else {
                navigate(`${AppRoutes.dashboard.awaiting}/${data.matchId}`)
            }
        }
    }

    useEffect(() => {
        window.addEventListener("message", handleEndMatch)
        return () => window.removeEventListener("message", handleEndMatch)
    }, [])

    if (loadingGame) return <ScreenLoader />

    // Render
    return (
        <>
            {!isLoaded && <ScreenLoader />}
            <iframe
                frameBorder="0"
                width={window.innerWidth}
                height={window.innerHeight}
                onLoad={() => setIsLoaded(true)}
                className={`w-full h-min-screen bg-primary-main ${!isLoaded && "invisible h-0"}`}
                src={game?.gamePath}
                scrolling="no"
            ></iframe>
        </>
    )
}

export default PlayCanvas
